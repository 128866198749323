import React from 'react';
import icons from '../assets/icons';
import {Icon, IconButton} from './common';

export default ({
  message,
  onDismiss,
}) => {
  return (
    <div className="toast">
      <IconButton className="toast_dismiss" onClick={onDismiss}>
        <Icon icon={icons.CLOSE} />
      </IconButton>
      <div className="toast_content">
        {message}
      </div>
    </div>
  );
};
