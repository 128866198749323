import React, { Component, Fragment } from 'react';
import { fileStates } from '../../utils/file-states.js';
import { removeAt } from '../../utils/utils';
import Explorer from '../../components/explorer';
import Toast from '../../components/Toast';
import StateLegend from '../../components/StateLegend.jsx';
import UserValidationFilter from '../../components/UserValidationFilter.jsx';
import Window from '../window';
import { Title, Button, IconButton, Preloader } from '../../components/common';
import Nav from '../../components/modal/Nav.jsx';
import Shortcuts from '../../components/modal/Shortcuts.jsx';
import { db } from '../../data/files';
import { api } from '../../api';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { toggler } from '../../components/Toggler.jsx';

const DOWNLOAD_WHITELIST = {
  'sanja.p@eyesee-research.com': ['2020057', '2020006', '2020066'],
  'jelena.m@eyesee-research.com': ['2712', '2020057', '2020006', '2020066']
}

const EXPORT_THRESHOLD = 5;

class Homepage extends Component {
  state = {
    filepath: '',
    toggleFile: false,
    messages: [],
    exporting: false,
    isModalVisible: false,
    docs: [],
    selectedDir: { project: '', cell: '', task: '', stimulus: '' },
    count: {},
    filtered: ['modified', 'active', 'cleaned', 'rejected', 'free'],
    stimulusPath: '',
    fps: 20,
    totalFrames: 0,
    currentFrame: 0,
    shortcutsVisible: false,
    playbackRateOfAll: 1,
    validUsers: [],
    isScreenCapture: 0,
    validationGroup: 'all',
    validUsersTimeMap: {},
    timelines: [],
    scrCanCleanOrReject: false,
    testersWorkersMap: {},
    currWorkerID: 0,
    taskOptions: []
  };

  resetStimulusData = () => {
    this.setState({ fps: 20, totalFrames: 0 });
  }

  updateFPS = fps => {
    this.setState({ fps });
  };

  updateTotalFrames = totalFrames => {
    this.setState({ totalFrames });
    window.totalFrames = totalFrames;
  };

  showNavModal = () => {
    this.setState({ isModalVisible: true });
  }

  onMessage = msg => {
    this.setState({
      messages: this.state.messages.concat(msg),
    });
  };

  openFile = file => {
    let currWorkerID = this.state.testersWorkersMap[file.userid];
    let jumpToTime = this.state.validUsersTimeMap[file.name.replace(/.csv/g, '')] ? Number(this.state.validUsersTimeMap[file.name.replace(/.csv/g, '')]) : 0;

    this.setState({
      filepath: file,
      isReadOnly: fileStates[file.state].disabled,
      currWorkerID,
      jumpToTime
    });
  };

  closeFile = () => {
    this.resetStimulusData();
    if (window.openedFile && window.openedFile.id !== -1) {
      db.update(window.openedFile.id, { active: false });
    }
    this.setState({
      filepath: '',
    });
  };

  dismiss = (index) => {
    this.setState({
      messages: removeAt(this.state.messages, index),
    });
  };

  onSave = () => {
    const { filepath } = this.state;
    let file = JSON.parse(JSON.stringify(filepath));
    file.state = 'M';
    db.update(filepath.id, file);
  };

  parseCellName = (cellName) => {
    let cellIdx = cellName.toLowerCase().indexOf("cell");
    let cellLength = cellIdx + 4;
    return (cellIdx >= 0 && cellIdx + cellLength < cellName.length) ? (cellName.slice(0, cellLength) + '_' + cellName.slice(cellLength)) : cellName;
  }

  onExport = () => {
    this.setState({ exporting: true });
    api.export({ ...this.state.selectedDir, scr: this.state.isScreenCapture })
      .then(response => {
        let filename = this.state.taskOptions.length > 1 ? `${this.state.selectedDir.project}_${this.state.selectedDir.cell}_${this.state.selectedDir.task}_${this.state.selectedDir.stimulus}.csv` : `${this.state.selectedDir.project}_${this.state.selectedDir.cell}_${this.state.selectedDir.stimulus}.csv`;
        let blob = new Blob([response.data], { type: "text/csv" });
        saveAs(blob, filename);
        this.setState({ exporting: false });
      });
  };

  onDownloadMP4 = () => {
    this.setState({ exporting: true });
    api.downloadVideos({ ...this.state.selectedDir, type: 'mp4' })
      .then(response => {
        let ct = new Date();
        ct = moment(ct).format('DD.MM.YYYY. HH:mm:ss');
        let blob = new Blob([response.data], { type: "application/octet-stream" });
        saveAs(blob, `${this.state.selectedDir.cell}_${this.state.selectedDir.task}_${this.state.selectedDir.stimulus}_${ct}.zip`);
        this.setState({ exporting: false });
      })
      .catch(() => this.onMessage("Downloading videos failed"))
      .finally(() => {
        this.setState({ exporting: false });
      });
  };

  onDownloadWEBM = () => {
    this.setState({ exporting: true });
    api.downloadVideos({ ...this.state.selectedDir, type: 'webm' })
      .then(response => {
        let ct = new Date();
        ct = moment(ct).format('DD.MM.YYYY. HH:mm:ss');
        let blob = new Blob([response.data], { type: "application/octet-stream" });
        saveAs(blob, `${this.state.selectedDir.cell}_${this.state.selectedDir.task}_${this.state.selectedDir.stimulus}_${ct}.zip`);
      })
      .catch(() => this.onMessage("Downloading videos failed"))
      .finally(() => {
        this.setState({ exporting: false });
      });
  };

  hideModal = () => {
    this.setState({ isModalVisible: false });
  };

  showShortcuts = () => {
    this.setState({ shortcutsVisible: true });
  };

  hideShortcuts = () => {
    this.setState({ shortcutsVisible: false });
  };

  generateTestersWorkersMap = (testers, workers) => {
    let testersWorkersMap = {};
    testers.forEach((tester, i) => testersWorkersMap[tester] = workers[i]);
    this.setState({ testersWorkersMap });
  }

  onGetTesters = (docs, selectedDir) => {
    let { project, cell, task, stimulus } = selectedDir;

    let proceed = files => {
      let isScreenCapture = docs.scr;
      let stimulusPath = docs.scr ? "" : docs.stimulus;
      let count = { total: 0, C: 0, R: 0 };
      let filtered = files.filter(file => {
        if (count[file.state]) count[file.state] += 1;
        else count[file.state] = 1;
        count.total++;
        return true;
      }).map(file => file.userid);
      window.testers = docs.testers.map(filename => filename.replace(/.csv/g, ''));
      window.workers = docs.workers;
      this.generateTestersWorkersMap(docs.testers, window.workers);
      let add = docs.testers.filter(doc => filtered.indexOf(doc) < 0);
      add.forEach(doc => {
        db.add({
          project,
          cell,
          task,
          stimulus,
          userid: doc,
          state: '',
          reject_reason: '',
          reject_reason_other: ''
        })
      });
      this.setState({ docs, selectedDir, count, stimulusPath, isScreenCapture, validUsers: window.testers });
      this.hideModal();
    }

    db.getTestFiles(selectedDir).then(files => {
      /**
       * We're experiencing a problem with Google's Firestore. Some users cannot always establish a connection due to unknown reasons.
       * We get no error, so we cannot handle it here.
       * We get an empty array and the consequence is creation of duplicates in the storage.
       * Deleting duplicates is manual work and takes forever, so we'd like to avoid it.
       * Whenever we get an empty array from Firestore, we will repeat the request one more time. If we get the same response, we will ask the user whether it is the first time on that test ( => an empty array is expected response).
       * If not, we doubt the Firestore doesn't work correctly and warn the user to close the app and report a problem (before it actually occurs)
      */
      if (files.length === 0)  {
        db.getTestFiles(selectedDir)
        .then(files => {
          if (files.length === 0) {
            let shouldProceed = window.confirm('Database didn\'t return testers for this test. If this is the first time entering the project, that is expected behaviour, so please ignore the message and press OK to proceed. Otherwise, press Cancel to avoid making duplicates in the DB and report the problem to the Tech immediately.');
            if (shouldProceed) proceed(files);
            else this.hideModal();
          } else {
            proceed(files);
          }
        })
      } else {
        proceed(files);
      }
    });
  };

  updateCount = count => {
    this.setState({ count });
  };

  updateReadOnly = fileState => {
    if (fileState === 'C' || fileState === 'R')
      this.setState({ isReadOnly: true });
    else
      this.setState({ isReadOnly: false });
  };

  filterFiles = filtered => {
    this.setState({ filtered });
  };

  getData = (selectedDir = this.state.selectedDir, taskOptions = this.state.taskOptions) => {
    this.closeFile();
    this.setState({taskOptions})
    api.fetchTesters({ ...selectedDir })
      .then(response => {
        this.onGetTesters(response.data, selectedDir);
        this.setPlaybackRateToAll(1);
      })
  };

  refresh = () => {
    this.getData();
  }

  updateCurrentFrame = (currentFrame, timeUpdateCallback = true) => {
    this.setState({ currentFrame });
    if (timeUpdateCallback) {
      document.getElementById("respondent_html5_api").currentTime = currentFrame / this.state.fps;
    }
  };

  onKeyDown = e => {
    let { currentFrame, totalFrames } = this.state;
    let fps = Math.ceil(Number(this.state.fps));

    if (window.isPlaying) return;

    if (e.shiftKey && !e.ctrlKey) {
      if (e.key === "ArrowRight") {
        let updatedCurrentFrame = currentFrame + fps;
        currentFrame = updatedCurrentFrame < totalFrames ? updatedCurrentFrame : totalFrames;
        this.updateCurrentFrame(currentFrame);
      }

      if (e.key === "ArrowLeft") {
        let updatedCurrentFrame = currentFrame - fps;
        currentFrame = updatedCurrentFrame > 0 ? updatedCurrentFrame : 1;
        this.updateCurrentFrame(currentFrame);
      }
    } else {
      if (e.key === "ArrowRight" && !e.ctrlKey && currentFrame < totalFrames - 1) {
        this.updateCurrentFrame(++currentFrame);
      }

      if (e.key === "ArrowLeft" && !e.ctrlKey && currentFrame > 1) {
        this.updateCurrentFrame(--currentFrame);
      }
    }
  }

  setPlaybackRateToAll = (rate) => {
    this.setState({ playbackRateOfAll: rate, initialRate: rate });
  }

  setOneToNeutral = () => {
    let tester_id = this.state.filepath.userid.replace(/.csv/g, '');
    api.oneToNeutral({ ...this.state.selectedDir, tester_id })
      .then(response => {
        let file = this.state.filepath;
        file.state = "M";
        db.update(file.id, file);
        this.setState({ toggleFile: !this.state.toggleFile })
      })
  }

  setToggleFile = () => {
    this.setState({ toggleFile: !this.state.toggleFile })
  }

  validateUsers = (validUsers, validationGroup) => {
    let validUsersTimeMap = {};
    let valid = window.testers;
    if (validationGroup === 'validated') {
      valid = [];
      outerLoop: for (let i = 0; i < validUsers.length; i++) {
        let usrTrim = validUsers[i].trim();
        if (!usrTrim) continue;
        let usrName = usrTrim.slice(0, usrTrim.lastIndexOf(',')).trim();
        let jumpToTime = usrTrim.slice(usrTrim.lastIndexOf(',') + 1);
        validUsersTimeMap[usrName] = jumpToTime || 0;
        for (let j = 0; j < window.testers.length; j++) {
          let tester = window.testers[j];
          if (tester.indexOf(usrName) >= 0) {
            valid.push(tester);
            continue outerLoop;
          }
        }
      }
    }
    this.setState({ validUsers: valid, validUsersTimeMap });
  }

  setValidationGroup = e => {
    this.setState({ validationGroup: e.target.value });
    if (e.target.value === 'all') this.validateUsers([], 'all');
  }

  updateStimulusPath = (stimulusPath) => {
    this.setState({ stimulusPath })
  }

  setTimelines = timelines => {
    this.setState({ timelines });
  }

  setScrCanCleanOrReject = scrCanCleanOrReject => {
    this.setState({ scrCanCleanOrReject });
  }

  render() {
    const {
      videoLength,
      exporting,
      filepath,
      messages,
      selectedDir,
    } = this.state;

    return (
      <Fragment>
        <Nav isVisible={this.state.isModalVisible} hideModal={this.hideModal} getData={this.getData} />
        <Shortcuts visible={this.state.shortcutsVisible} hideModal={this.hideShortcuts} />

        <div className="main-wrapper">
          <aside className='app-sidebar'>
            <span>
              <Title style={{ padding: '0 12px', 'justifyContent': 'space-between' }}>
                <span>explorer</span>
                <span>
                  <IconButton title="Change project" onClick={this.showNavModal}>
                    <i className="material-icons projects-headline">view_headline</i>
                  </IconButton>
                  <IconButton title="Refresh" onClick={this.refresh}>
                    <i className="material-icons">refresh</i>
                  </IconButton >
                  <IconButton title="Shortcuts" onClick={this.showShortcuts}>
                    <i className="material-icons">help_outline</i>
                  </IconButton >
                </span>
              </Title>
            </span>
            <Explorer
              filepath={filepath}
              onClick={this.openFile}
              selectedDir={selectedDir}
              docs={this.state.docs}
              filtered={this.state.filtered}
              onUpdateCount={this.updateCount}
              updateReadOnly={this.updateReadOnly}
              setToggleFile={this.setToggleFile}
              validUsers={this.state.validUsers}
              validationGroup={this.state.validationGroup}
              timelines={this.state.timelines}
              isScreenCapture={this.state.isScreenCapture}
              testersWorkersMap={this.state.testersWorkersMap}
              scrCanCleanOrReject={this.state.scrCanCleanOrReject}
              currWorkerID={this.state.currWorkerID}
            />
            <StateLegend count={this.state.count} filterFiles={this.filterFiles} />
            <div className='app-sidebar_footer'>
              <UserValidationFilter
                validateUsers={this.validateUsers}
                validationGroup={this.state.validationGroup}
                setValidationGroup={this.setValidationGroup}
              />
              <div className="footer-section">
                <Button
                  className='block btn--primary'
                  onClick={this.onExport}
                  disabled={exporting || (this.state.count.C < EXPORT_THRESHOLD && this.state.count.C + this.state.count.R != this.state.count.total)}
                >export csv</Button>
                <Button
                  className='block btn--primary'
                  onClick={this.onExport}
                  disabled={true}
                >calculate KPIs</Button>
              </div>
              <div className="footer-section bottom">
                <Button
                  className='block'
                  onClick={this.onDownloadMP4}
                  disabled={exporting || (!DOWNLOAD_WHITELIST[window.localStorage.getItem('emo_user_email')] || DOWNLOAD_WHITELIST[window.localStorage.getItem('emo_user_email')].indexOf(this.state.selectedDir.project) < 0)}
                >
                  {
                    exporting ?
                      <Preloader />
                      :
                      <span>download mp4</span>
                  }
                </Button>
                <Button
                  className='block'
                  onClick={this.onDownloadWEBM}
                  disabled={exporting || (!DOWNLOAD_WHITELIST[window.localStorage.getItem('emo_user_email')] || DOWNLOAD_WHITELIST[window.localStorage.getItem('emo_user_email')].indexOf(this.state.selectedDir.project) < 0)}
                >
                  {
                    exporting ?
                      <Preloader />
                      :
                      <span>download webm</span>
                  }
                </Button>
              </div>
            </div>
          </aside>
          {toggler.render()}
          <div className="frame">
            {
              filepath &&
              <Window
                key={filepath}
                filepath={filepath}
                selectedDir={selectedDir}
                videoLength={videoLength}
                setOneToNeutral={this.setOneToNeutral}
                onMessage={this.onMessage}
                onClose={this.closeFile}
                onSave={this.onSave}
                stimulusPath={this.state.stimulusPath}
                updateStimulusPath={this.updateStimulusPath}
                isScreenCapture={this.state.isScreenCapture}
                setTimelines={this.setTimelines}
                timelines={this.state.timelines}
                setScrCanCleanOrReject={this.setScrCanCleanOrReject}
                isReadOnly={this.state.isReadOnly}
                updateFPS={this.updateFPS}
                fps={this.state.fps}
                totalFrames={this.state.totalFrames}
                updateTotalFrames={this.updateTotalFrames}
                currentFrame={this.state.currentFrame}
                updateCurrentFrame={this.updateCurrentFrame}
                playbackRateAll={this.state.playbackRateOfAll}
                setPlaybackRateAll={this.setPlaybackRateToAll}
                toggleFile={this.state.toggleFile}
                currWorkerID={this.state.currWorkerID}
                jumpToTime={this.state.jumpToTime}
              />
            }
          </div>
        </div>

        <div className="toast-container">
          {
            messages.map((message, i) =>
              <Toast
                key={i}
                message={message}
                onDismiss={() => this.dismiss(i)}
              />)
          }
        </div>
      </Fragment>
    );
  }

  componentDidMount() {
    toggler.init(
      document.getElementsByClassName('app-sidebar')[0],
      document.getElementsByClassName('frame')[0]
    );
    if (this.props.selectedDir) {
      let { project, cell, task, stimulus } = this.props.selectedDir;
      if (project && cell && task && stimulus) {
        let dir = { project, cell, task, stimulus, stimuli: stimulus };
        api.fetchTesters({ ...dir })
          .then(response => {
            this.onGetTesters(response.data, dir);
          })
      }
      return;
    }
    this.setState({ isModalVisible: true });
    db.listenForChanges();
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }
}

export default Homepage;
