import React, { useState, useEffect } from 'react';
import './index.css';
import Block from './Block.jsx';
import { timeline } from '../../store/timeline.js';

export default function Timeline({ data = [], saveTimeline }) {
  let [timelineData, setTimelineData] = useState([]);
  let [canSave, setCanSave] = useState(false);

  useEffect(() => {
    let el = document.querySelector('.panel_main')
    if (!el) return;
    el.classList.add('no-overflow');
  }, []);

  useEffect(() => {
    if (!data || !data.length) return;
    setTimelineData(data);
  }, [data]);

  useEffect(() => {
    setCanSave(() => timeline.isListValid(timelineData));
  }, [timelineData]);

  let onChangeTimelineData = (idx, dataName, dataVal) => {
    setTimelineData(timeline.onChangeData(idx, dataName, dataVal, [...timelineData]));
  }

  let onSave = e => {
    e.preventDefault();
    e.stopPropagation();
    saveTimeline(timelineData);
  }

  let addBlock = (i) => {
    setTimelineData(timeline.addBlock([...timelineData], i));
  }

  let removeBlock = idx => {
    setTimelineData(timeline.removeBlock([...timelineData], idx));
  }

  return (
    <div className="timeline chart-wrapper">
      {
        timelineData.map((block, i) => (
          <Block
            key={block.id}
            index={i}
            data={block}
            onChange={(name, val) => onChangeTimelineData(i, name, val)}
            removeBlock={() => removeBlock(i)}
            addBlock={() => addBlock(i)}
          />
        ))
      }

      <form className="timeline-submit center" onSubmit={onSave}>
        <button className="btn btn--primary" type='submit' disabled={!canSave}>Save</button>
      </form>
    </div>
  )
}
