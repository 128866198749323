import React from 'react';
import classnames from 'classnames';

const Title = ({
  as: T,
  className,
  ...props
}) => (
  <T className={classnames('title', {[className]: className})} {...props} />
);

Title.defaultProps = {
  as: 'div',
  className: '',
};

export default Title;
