import React, { Component, Fragment } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export default class Stimulus extends Component {
  player = null;

  videoJSOptions = {
    controls: true,
    width: 640,
    height: 480,
    sources: [{
      src: this.props.src,
      type: 'video/mp4'
    }]
  };

  onIsPlaying = isPlaying => {
    if (isPlaying)
      this.player.play();
    else
      this.player.pause();
  }

  componentDidMount() {
    this.player = videojs(
      document.getElementById('stimulus'),
      this.videoJSOptions, () => {
        this.player.on('error', () => {
          this.player.pause();
          this.player.currentTime(0);
          this.player.load();
        });
      }
    );
  };

  componentDidUpdate(prevProps) {
    if (this.props.src && this.props.src !== prevProps.src) {
      this.player.src(this.props.src);
      setTimeout(() => {
        this.player.playbackRate(this.props.playbackRate)
      }, 10)
    }
    if (this.player.paused() && this.props.currentTime !== prevProps.currentTime) {
      this.player.currentTime(this.props.currentTime);
    }
    if (this.props.playbackRate !== prevProps.playbackRate) {
      this.player.playbackRate(this.props.playbackRate);
   }
    if (this.props.isPlaying !== prevProps.isPlaying) {
      this.onIsPlaying(this.props.isPlaying);
    }
  }

  render() {
    let style = this.props.isStimulusVisible ? { display: 'flex' } : { display: 'none' };
    return (
      <Fragment>
        <div className="video" style={style}>
          <div>
            <video id="stimulus" className="video-js vjs-default-skin" preload="auto" muted></video>
          </div>
        </div>
      </Fragment>
    )
  };
}
