import React, {Component} from 'react';
import EditorForm from '../form';
import {normalizeState, clampState} from '../utils';

class EditorSingle extends Component {
  state = this.props.data;

  onChange = (evt, normalize=false) => {
    const {name, value} = evt.target;

    if (normalize) {
      this.setState(normalizeState(name));
      return;
    }

    this.setState(clampState(name, value));
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data))
     this.setState(this.props.data);
  }

  render() {
    return (
      <EditorForm
        data={this.state}
        frames={this.props.frames}
        fps={this.props.fps}
        onChange={this.onChange}
        onEdit={() => this.props.onEdit(this.state)}
        onCancel={this.props.onCancel}
        isReadOnly={this.props.isReadOnly}
      />
    );
  }
};

export default EditorSingle;
