import emitter from '../emitter.js';
import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyDbTKxGY5H6f1fSa3DcIkBuYxTs8wIQG88",
  authDomain: "emo-viewer.firebaseapp.com",
  databaseURL: "https://emo-viewer.firebaseio.com",
  projectId: "emo-viewer",
  storageBucket: "emo-viewer.appspot.com",
  messagingSenderId: "1003238530220",
  appId: "1:1003238530220:web:01130d4069b4c78d"
};

export const db = {
  initialize() {
    firebase.initializeApp(config);
    // firebase.firestore().settings({experimentalForceLongPolling: true});
  },

 add(file) {
    return firebase.firestore().collection('files').add(file);
  },

  getAll() {
    return firebase.firestore().collection("files").get().then(querySnapshot => {
      let list = [];
      querySnapshot.forEach(doc => {
        list.push({ ...doc.data(), id: doc.id });
      });
      return Promise.resolve(list);
    });
  },

  get(id) {
    let file = firebase.firestore().collection('files').doc(id);
    return file.get().then(data => Promise.resolve(data.data()));
  },

  getAllUntouched(data) {
    return firebase.firestore().collection('files')
      .where("project", "==", data.project)
      .where("cell", "==", data.cell)
      .where("task", "==", data.task)
      .where("stimulus", "==", data.stimulus)
      .where('state', '==', '')
      .get()
      .then(querySnapshot => {
        let list = [];
        querySnapshot.forEach(doc => {
          list.push({ ...doc.data(), id: doc.id });
        });
        return Promise.resolve(list);
      });
  },

  update(id, data) {
    return firebase.firestore().collection("files").doc(id).set(data, { merge: true })
      .then(response => {
        return Promise.resolve(true)
      })
      .catch(err => console.log('error', err))
      .finally(() => Promise.resolve(true));
  },

  initStatusListener(file) {
    var userStatusDatabaseRef = firebase.database().ref('/status/' + file.id);
    var isOfflineForDatabase = {
      active: false,
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
    var isOnlineForDatabase = {
      active: true,
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    firebase.database().ref('.info/connected').on('value', function (snapshot) {
      userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
        userStatusDatabaseRef.set(isOnlineForDatabase);
      });
    });
  },

  getTestFiles(data) {
    return firebase.firestore().collection('files')
      .where("project", "==", data.project)
      .where("cell", "==", data.cell)
      .where("task", "==", data.task)
      .where("stimulus", "==", data.stimulus)
      .get().then(querySnapshot => {
        let list = [];
        querySnapshot.forEach(doc => {
          list.push({ ...doc.data(), id: doc.id });
        });
        return Promise.resolve(list);
      });
  },

  markAllCleaned(data, failed) {
    let batch = firebase.firestore().batch();

    let filesRef = firebase.firestore().collection('files')
      .where("project", "==", data.project)
      .where("cell", "==", data.cell)
      .where("task", "==", data.task)
      .where("stimulus", "==", data.stimulus)
      .get().then(response => {
        response.docs.map(doc => {
          if (failed.indexOf(doc.data().userid.replace(/.csv/g, '')) < 0) batch.update(doc.ref, { state: 'C' });
        });
        batch.commit();
      })
  },

  listenForChanges() {
    firebase.firestore().collection("files")
      .onSnapshot(function (snapshot) {
        snapshot.docChanges().forEach(function (change) {
          if (change.type === "added") {
            //    console.log("New file: ", change.doc.data());
          }
          if (change.type === "modified") {
            //  console.log("Modified file: ", change.doc.data());
            emitter.emit('fileUpdated', change.doc.data());
          }
          if (change.type === "removed") {
            //      console.log("Removed file: ", change.doc.data());
          }
        });
      });
  }
}
