import React, {Component} from 'react';
import {fileStates} from '../utils/file-states.js';
let states = Object.entries(fileStates).map(pair => {
	return {...pair[1], id: pair[0]};
});

export default class StateLegend extends Component {
  state = {
    filtered: ['modified', 'cleaned', 'rejected', 'free'],
    selectedAll: true
  }

  toggleStateSelected = state => {
    let filtered = JSON.parse(JSON.stringify(this.state.filtered));
    let index = filtered.indexOf(state);
    if (index < 0) {
      filtered.push(state);
    } else {
      filtered.splice(index, 1);
    }
    let selectedAll = filtered.length === 5;
    this.setState({filtered, selectedAll});
  }

  toggleSelectedAllStates = e => {
    let selectedAll = e.target.checked;
    let filtered = selectedAll ? ['modified', 'cleaned', 'rejected', 'free'] : [];
    this.setState({filtered, selectedAll});
  }

  render () {
    return (
      <div className="state-legend">
        <h4 className="state-legend--title">
          <i className="material-icons">filter_list</i>
          <span>Filter</span>
         </h4>

        <ul className="states">
          {
            states.map((fs, i) => {
              let stateClass = "state-color" + (this.state.filtered.indexOf(fs.state) >= 0 ? ' checked' : '');
              return (
                <li key={i} className="state" onClick={() => this.toggleStateSelected(fs.state)}>
                  <div className={stateClass} style={{background: fs.color}}></div>
                  <label> {fs.state} <span className="state-legend--stat-num" >({this.props.count[fs.id] || 0})</span> </label>
                </li>
              )
            })
          }
          <div className="states-selected">
            <input type="checkbox" checked={this.state.selectedAll} onChange={this.toggleSelectedAllStates} id="selectAll"/>
            <label htmlFor="selectAll">All <span className="state-legend--stat-num">({this.props.count.total})</span></label>
          </div>
        </ul>
      </div>
    )
  };

	componentDidUpdate(prevProps, prevState) {
		if (JSON.stringify(prevState.filtered) !== JSON.stringify(this.state.filtered)) {
			this.props.filterFiles(this.state.filtered);
		}
	};
}
