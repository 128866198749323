import React, { Component, Fragment } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import emitter from '../../emitter';

const PLAYBACK_RATES = [0.3, 0.5, 1, 2, 3, 5, 10];
export default class Respondent extends Component {
  player = null;
  currentTime = null;
  lastPlaybackRate = 1;

  videoJSOptions = {
    controls: true,
    sources: [{
      src: "https://player.vimeo.com/external/196344457.sd.mp4?s=73d5e633a3c43c365fb5120f42335a4a676b1dd7&profile_id=164",
      type: 'video/mp4'
    }],
    loop: false,
    video: { frameRate: { ideal: 20, max: 20 } },
    playbackRates: PLAYBACK_RATES,
    controlBar: {
      fullscreenToggle: true,
      pictureInPictureToggle: true,
      volumePanel: {
        inline: false,
        volumeControl: {
          vertical: true
        }
      }
    },
    width: 640,
    height: 480
  };

  onTimeUpdate = (e) => {
    if (this.player.currentTime() === 0) this.player.playbackRate(this.lastPlaybackRate)
    if (this.currentTime === this.player.currentTime()) return;
    let currentFrame = Math.ceil(this.player.currentTime() * this.props.fps);
    this.props.updateCurrentFrame(currentFrame, false);
    this.updateCurrentTime();
  };

  focusPlayPauseButton = () => {
    let el = document.querySelector("#respondent .vjs-play-control.vjs-control.vjs-button");
    el.focus();
  }

  onFullscreenChange = () => {
    let videoControlsEl = document.getElementsByClassName("vjs-control-bar")[0];
    this.focusPlayPauseButton();
    if (document.fullscreenElement) {
      videoControlsEl.classList.add('inline');
      return;
    }
    videoControlsEl.classList.remove('inline');
  };

  updateIsPlaying = () => {
    document.activeElement.blur();
    this.props.updateIsPlaying(!this.player.paused());
  };

  onPlaybackRateChange = () => {
    this.props.updatePlaybackRate(this.player.playbackRate())
  };

  updateCurrentTime = () => {
    this.currentTime = this.player.currentTime();
    emitter.emit('timeUpdated', this.currentTime);
    // if (this.player.paused())
    this.props.updateTime(this.currentTime);
  };

  speedUp = () => {
    let playbackRate = this.player.playbackRate();
    let pbrIndex = PLAYBACK_RATES.findIndex(rate => rate === playbackRate);
    let newIdx = pbrIndex + 1;
    if (PLAYBACK_RATES[newIdx] !== playbackRate && newIdx < PLAYBACK_RATES.length) {
      this.player.playbackRate(PLAYBACK_RATES[newIdx]);
    }
  };

  slowDown = () => {
    let playbackRate = this.player.playbackRate();
    let pbrIndex = PLAYBACK_RATES.findIndex(rate => rate === playbackRate);
    let newIdx = pbrIndex - 1;
    if (PLAYBACK_RATES[newIdx] !== playbackRate && newIdx >= 0) {
      this.player.playbackRate(PLAYBACK_RATES[newIdx]);
    }
  };

  onKeyDown = e => {
    e.stopPropagation();
    if (e.code === "ArrowRight" && e.ctrlKey && !this.isSliding) {
      this.player.playbackRate(0.3);
      this.isSliding = true;
    }

    if (e.code === "ArrowUp") {
      e.preventDefault();
      this.speedUp();
      return;
    }
    if (e.code === "ArrowDown") {
      e.preventDefault();
      this.slowDown();
      return;
    }

    if (e.code === "Space" && !window.preventPlaying) {
      e.preventDefault();

      if (this.player.paused())
        this.player.play();
      else
        this.player.pause();
    }
  }

  componentDidMount() {
    this.player = videojs(
      document.getElementById('respondent'),
      this.videoJSOptions,
      () => {
        this.player.on('loadeddata', () => {
          this.player.on('timeupdate', this.onTimeUpdate);
          this.player.on('ratechange', this.onPlaybackRateChange)
          this.player.on('play', this.updateIsPlaying);
          this.player.on('pause', this.updateIsPlaying);
          this.player.on('ended', () => {
            this.player.pause()
            this.player.currentTime(0.0001);
          });
          document.addEventListener("fullscreenchange", this.onFullscreenChange);
          document.addEventListener("keydown", this.onKeyDown);
          if (!isNaN(this.props.jumpToTime) && this.props.jumpToTime && this.props.jumpToTime <= this.player.duration())
            this.player.currentTime(this.props.jumpToTime)
        });
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.src !== prevProps.src) {
      this.player.src(this.props.src);
      this.lastPlaybackRate = this.props.playbackRateAll;
    }
  };

  componentWillUnmount() {
    document.removeEventListener("fullscreenchange", this.onFullscreenChange);
    document.removeEventListener("keydown", this.onKeyDown);
  };

  render() {
    let wrapperStyle = this.props.isStimulusVisible ? {} : { margin: 'auto' };
    return (
      <Fragment>
        <div className="video" style={wrapperStyle}>
          <div>
            <video className="video-js vjs-default-skin" id="respondent" preload="auto"></video>
          </div>
        </div>
      </Fragment>
    )
  };
}
