import {keys} from 'ramda';

const COLORS = {
  happy: '#ffef83',
  sad: '#57bae3',
  disgust: '#aee68e',
  interested: '#d78380',
  confused: '#cca4c4',
  // puzzled: 'red',
  surprised: '#83b4d7',
  neutral: '#aaaaab',
  afraid: '#3b1b5b',
  affraid: '#3b1b5b',
};

export const labels = {
  init: function (chartData) {
    return (keys(chartData[0]))
      .filter((s) => s !== 't' && s !== 'selected')
      .map((label) => ({
        selected: false,
        color: COLORS[label],
        value: label,
      }));
  }
}
