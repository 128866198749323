import React, { Component } from 'react';
import { Title, Button, IconButton } from '../../components/common';
import VideoLengthForm from '../../components/VideoLengthForm';

export default class Controls extends Component {
  updateCurrentFrame = e => {
    e.preventDefault();
    let val = document.getElementById("currentFrame").value;
    if (isNaN(val)) return;
    if (val < 0 || val > this.props.totalFrames) return;
    this.props.updateCurrentFrame(Number(val));
  }

  goToPreviousFrame = () => {
    if (this.props.currentFrame <= 0) return;
    this.props.updateCurrentFrame(this.props.currentFrame - 1);
  }

  goToNextFrame = () => {
    if (this.props.currentFrame >= this.props.totalFrames) return;
    this.props.updateCurrentFrame(this.props.currentFrame + 1);
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentFrame !== prevProps.currentFrame)
      document.getElementById("currentFrame").value = this.props.currentFrame;
  }

  render() {
    let style = this.props.isStimulusVisible ? { justifyContent: 'space-between' } : { justifyContent: 'center' };
    return (
      <div className="video-controls-wrapper" style={style}>
        <div className='video-controls'>
          <div className="video-controls-left">
            <span>{this.props.elapsedTime}</span>
          </div>
          <div className="app-sidebar-title">
            <div className="vlf-wrapper">
              <Title style={{ padding: '0px' }}>fps</Title>
              <VideoLengthForm val={this.props.fps} />
            </div>
            <div className="vlf-wrapper">
              <Title style={{ padding: '0px' }}>frames</Title>
              <VideoLengthForm val={this.props.totalFrames} />
            </div>
          </div>

          <div className="video-controls-right frame-ctrls">
            <IconButton title='previous frame' onClick={this.goToPreviousFrame}>
              <i className="material-icons">skip_previous</i>
            </IconButton>
            <form className="frames-number" onSubmit={this.updateCurrentFrame}>
              <input type="text" title="frame" id="currentFrame" />
            </form>
            <IconButton title='next frame' onClick={this.goToNextFrame}>
              <i className="material-icons">skip_next</i>
            </IconButton>
          </div>

          {/* <div>
            <Button onClick={this.props.setPlaybackRateAll}>Apply speed to all</Button>
          </div> */}
        </div>
        <div className="stimulus-view no-select">
          <span className="no-select"
            title={this.props.isStimulusVisible ? 'Hide stimulus' : 'Show stimulus'}
            onClick={this.props.toggleStimulusVisibility}>
            {
              this.props.isStimulusVisible ?
                <i className="material-icons stimulus-on no-select">toggle_on</i>
                :
                <i className="material-icons stimulus-off no-select">toggle_off</i>
            }
          </span>
        </div>
      </div>
    )
  }
}
