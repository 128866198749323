import React, {Component} from 'react';
import Select from 'react-select';
import {Label, Button, IconButton} from '../common';
import {api} from '../../api';

export default class Nav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dir: {},
      projectOptions: [],
      cellOptions: [],
      taskOptions: [],
      stimuliOptions: [],
      selectedProject: {value: '', label: ''},
      selectedCell: {value: '', label: ''},
      selectedTask: {value: '', label: ''},
      selectedStimulus: {value: '', label: ''},
      canSelect: false
    }
  }

  componentDidMount() {
     api.fetchProjects()
       .then(response => {
         let dir = this.sortFolders(response.data);
         let projectOptions = Object.keys(dir).map(p => ({value: p, label: p}));
         this.setState({dir, projectOptions});
       });
  }

  sortFolders = paths => {
    let projects = {};
    for (let i = 0; i < paths.length; i++) {
      let q = paths[i].split('/');
      let project = q[0];
      let cell = q[1];
      let task = q[2];
      let stimulus = q[3];
      if (projects[project]) {
       if (projects[project][cell]) {
         if (projects[project][cell][task]) projects[project][cell][task].push(stimulus);
         else projects[project][cell][task] = [stimulus];
       }
       else projects[project][cell] = {[task]: [stimulus]};
      } else {
        projects[project] = {
          [cell] : {
            [task] : [stimulus]
          }
        }
      }
    }
    return projects;
  }

  onSelectProject = p => {
    let cellOptions = Object.keys(this.state.dir[p.value]).map(c => ({value: c, label: c}));
    let selectedProject = p;
    let selectedCell = null;
    let selectedTask = null;
    let selectedStimulus = null;
    this.setState({cellOptions, selectedProject, selectedCell, selectedTask, selectedStimulus, canSelect: false});
    window.setTimeout(() => {
      if(Object.keys(this.state.dir[p.value]).length === 1) {
        let cell = Object.keys(this.state.dir[p.value])[0];
        this.onSelectCell({value: cell, label: cell});
      }
    }, 0);

  }

  onSelectCell = c => {
     let taskOptions = Object.keys(this.state.dir[this.state.selectedProject.value][c.value]).map(t => ({value: t, label: t}));
     let selectedCell = c;
     let selectedTask = null;
     let selectedStimulus = null;
     this.setState({taskOptions, selectedCell, selectedTask, selectedStimulus, canSelect: false});
     window.setTimeout(() => {
       if(Object.keys(this.state.dir[this.state.selectedProject.value][c.value]).length === 1) {
         let task = Object.keys(this.state.dir[this.state.selectedProject.value][c.value])[0];
         this.onSelectTask({value: task, label: task});
       }
     }, 0);
  }

  onSelectTask = t => {
     let stimuliOptions = this.state.dir[this.state.selectedProject.value][this.state.selectedCell.value][t.value].map(s => ({value: s, label: s}));
     let selectedTask = t;
     let selectedStimulus = null;
     this.setState({stimuliOptions, selectedTask, selectedStimulus, canSelect: false});
     window.setTimeout(() => {
       if(Object.keys(this.state.dir[this.state.selectedProject.value][this.state.selectedCell.value][t.value]).length === 1) {
         let stimulus = this.state.dir[this.state.selectedProject.value][this.state.selectedCell.value][t.value][0];
         this.onSelectStimulus({label: stimulus, value: stimulus});
       }
     }, 0);
  }

  onSelectStimulus = s => {
     let selectedStimulus = s;
     this.setState({selectedStimulus, canSelect: true});
  }

  onGetTesters = () => {
    let dir = {
      project: this.state.selectedProject.value,
      cell: this.state.selectedCell.value,
      task: this.state.selectedTask.value,
      stimulus: this.state.selectedStimulus.value,
      stimuli: this.state.selectedStimulus.value,
    }
    this.props.getData(dir, this.state.taskOptions);
  }

  render() {
    let modalStyle = this.props.isVisible ? {display: 'flex'} : {display: 'none'};

    return (
      <div id="app-modal" style={modalStyle}>
        <div className="modal-overlay" onClick={this.props.hideModal}></div>
        <div className="modal-content">
          <div className="modal-header">
            <h5>Choose Project</h5>
            <IconButton>
              <i className="material-icons" onClick={this.props.hideModal}>close</i>
            </IconButton>
          </div>

          <div className="modal-body">
            <div className="nav-control">
              <Label>Project: </Label>
              <Select options={this.state.projectOptions} onChange={this.onSelectProject} value={this.state.selectedProject}></Select>
            </div>
            <div className="nav-control">
              <Label>Cell: </Label>
              <Select options={this.state.cellOptions} onChange={this.onSelectCell} value={this.state.selectedCell}></Select>
            </div>
            <div className="nav-control">
              <Label>Task: </Label>
              <Select options={this.state.taskOptions} onChange={this.onSelectTask} value={this.state.selectedTask}></Select>
            </div>
            <div className="nav-control">
              <Label>Stimulus: </Label>
              <Select options={this.state.stimuliOptions} onChange={this.onSelectStimulus} value={this.state.selectedStimulus}></Select>
            </div>
          </div>

          <div className="modal-footer">
            <Button onClick={this.props.hideModal}>Cancel</Button>
            <Button className='btn--primary' onClick={this.onGetTesters} disabled={!this.state.canSelect}>Select</Button>
          </div>
        </div>
      </div>
    )
  }
}
