import React, { useState, useEffect } from 'react';

export default function Block({ data, index, onChange, addBlock, removeBlock }) {
  let [areOptionsDisabled, setAreOptionsDisabled] = useState(false);
  let [isNeutral, setIsNeutral] = useState(false);

  useEffect(() => {
    setAreOptionsDisabled(!data.sawTheAd);
  }, [data.sawTheAd]);

  useEffect(() => {
    setIsNeutral(data.neutral);
  }, [data.neutral]);

  let handleChange = e => {
    onChange(e.target.name, Number(e.target.checked));
  };

  return (
    <div className="timeline-block">
      <div className="ad-emotions">
        <p className="timeline-block--title"> {index + 1}. Emotions:</p>
        <div className="timeline-block--data">
          <div className="input-field">
            <input type="checkbox" id={"t-neutral" + index} checked={data.neutral} name="neutral" onChange={handleChange} disabled={areOptionsDisabled} />
            <label htmlFor={"t-neutral" + index}>Neutral</label>
          </div>
          <div className="input-field">
            <input type="checkbox" id={"t-happy" + index} checked={data.happy} name="happy" onChange={handleChange} disabled={areOptionsDisabled || isNeutral} />
            <label htmlFor={"t-happy" + index}>Happy</label>
          </div>
          <div className="input-field">
            <input type="checkbox" id={"t-surprised" + index} checked={data.surprised} name="surprised" onChange={handleChange} disabled={areOptionsDisabled || isNeutral} />
            <label htmlFor={"t-surprised" + index}>Surprised</label>
          </div>
          <div className="input-field">
            <input type="checkbox" id={"t-interested" + index} checked={data.interested} name="interested" onChange={handleChange} disabled={areOptionsDisabled || isNeutral} />
            <label htmlFor={"t-interested" + index}>Interested</label>
          </div>
          <div className="input-field">
            <input type="checkbox" id={"t-confused" + index} checked={data.confused} name="confused" onChange={handleChange} disabled={areOptionsDisabled || isNeutral} />
            <label htmlFor={"t-confused" + index}>Confused</label>
          </div>
          <div className="input-field">
            <input type="checkbox" id={"t-disgusted" + index} checked={data.disgusted} name="disgusted" onChange={handleChange} disabled={areOptionsDisabled || isNeutral} />
            <label htmlFor={"t-disgusted" + index}>Disgusted</label>
          </div>
          <div className="input-field">
            <input type="checkbox" id={"t-afraid" + index} checked={data.afraid} name="afraid" onChange={handleChange} disabled={areOptionsDisabled || isNeutral} />
            <label htmlFor={"t-afraid" + index}>Afraid</label>
          </div>
          <div className="input-field">
            <input type="checkbox" id={"t-sad" + index} checked={data.sad} name="sad" onChange={handleChange} disabled={areOptionsDisabled || isNeutral} />
            <label htmlFor={"t-sad" + index}>Sad</label>
          </div>
        </div>
      </div>

      <div className="timeline-right-ctrls">

        <div className="ad-interactions">
          <p className="timeline-block--title"> {index + 1}. Interaction:</p>
          <div className="timeline-block--data">
            <div className="input-field">
              <input type="checkbox" id={"t-sawTheAd" + index} checked={data.sawTheAd} name="sawTheAd" onChange={handleChange} />
              <label htmlFor={"t-sawTheAd" + index}>Saw the AD</label>
            </div>
            <div className="input-field">
              <input type="checkbox" id={"t-unmute" + index} checked={data.unmute} name="unmute" onChange={handleChange} disabled={areOptionsDisabled} />
              <label htmlFor={"t-unmute" + index}>Unmute</label>
            </div>
            <div className="input-field">
              <input type="checkbox" id={"t-zoom" + index} checked={data.zoom} name="zoom" onChange={handleChange} disabled={areOptionsDisabled} />
              <label htmlFor={"t-zoom" + index}>Zoom</label>
            </div>
            <div className="input-field">
              <input type="checkbox" id={"t-skipped" + index} checked={data.skipped} name="skipped" onChange={handleChange} disabled={areOptionsDisabled} />
              <label htmlFor={"t-skipped" + index}>Skipped</label>
            </div>
          </div>
        </div>

        <div className="timeline-block--ctrls">
          <i className="material-icons add" onClick={addBlock}>add_circle</i>
          <i className="material-icons remove" onClick={removeBlock}>delete</i>
        </div>
      </div>


    </div>
  )
}
