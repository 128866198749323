import React from 'react';
import classnames from 'classnames';

const FromControl = ({
  className,
  ...props
}) => (
  <div className={classnames('form-control', {[className]: className})} {...props} />
);

export default FromControl;
