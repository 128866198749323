export const timeDot = {
  position: 63,

  el: function () {
    return document.getElementById('time-dot');
  },

  setPosition: function (position) {
    this.position = position;
    this.el().setAttribute('x1', position);
    this.el().setAttribute('x2', position);
  },

  show: function () {
    this.el().style.visibility = 'visible';
  },

  hide: function () {
    this.el().style.visibility = 'hidden';
  }
};
