import firebase from 'firebase';
import { api } from '../api';
import { db } from '../data/files.js';


export const auth = {
  initPresenceListener() {
    var uid = firebase.auth().currentUser.uid;
    var userStatusDatabaseRef = firebase.database().ref('/status/' + uid);
    var isOfflineForDatabase = {
      state: 'offline',
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
    var isOnlineForDatabase = {
      state: 'online',
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };

    firebase.database().ref('.info/connected').on('value', function (snapshot) {
      if (snapshot.val() == false) {
        return;
      };
      userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function () {
        userStatusDatabaseRef.set(isOnlineForDatabase);
      });
    });
  },

  checkUser() {
    return api.userCheck().then(response => {
      let t = new URLSearchParams(window.location.search).get('t');
      if (response.data.status === 400) return Promise.reject();
      window.localStorage.setItem("emo_user_key", t);
      window.localStorage.setItem("emo_user_email", response.data.user_email);
      return Promise.resolve(response.data);
    }).catch(err => Promise.reject(err))
  },

  login(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password)
      .then(response => {
        return Promise.resolve(true);
      })
      .catch(err => Promise.reject(false))
  },

  eyeseeLogout() {
    let getEncodedToken = () => {
      return window.encodeURIComponent(window.localStorage.getItem('emo_user_key'));
    }
    let url = `https://eyesee-hub.com/logout?t=${getEncodedToken()}&app_name=web-emoviewer`;
    window.location.href = url;
  },

  logout() {
    if (window.openedFile && window.openedFile.id !== -1) {
      db.update(window.openedFile.id, { active: false })
        .finally(() => {
          auth.eyeseeLogout();
          return firebase.auth().signOut();
        });
    } else {
      auth.eyeseeLogout();
      return firebase.auth().signOut();
    }
  },

  isLoggedIn() {
    let isLoggedIn = firebase.auth().currentUser && localStorage.getItem('emo_user_key');
    return isLoggedIn;
  },

  listenToChange() {
    firebase.auth().onAuthStateChanged(authUser => {
      authUser
        ? localStorage.setItem('authUser', JSON.stringify(authUser))
        : localStorage.removeItem('authUser')
    });
  }
}
