import React, { Component } from 'react';
import { Label, FormControl, Button } from '../common';

export default class FileStateDialogue extends Component {
  constructor(props) {
    super(props);
    this.rejectReasons = [
      'no face',
      'photo',
      'dark / light',
      'eat / talk',
      'duplicate',
      'tester',
      'not watching / distracted',
      'child',
      'other'
    ]
    this.state = {
      initialFileState: '',
      file: JSON.parse(JSON.stringify(this.props.file))
    }
  }

  onSetFileState = e => {
    e.preventDefault();
    if (JSON.stringify(this.state.file) === JSON.stringify(this.props.file)) {
      this.props.closeStateDialogue();
      return;
    }
    let file = JSON.parse(JSON.stringify(this.state.file));
    this.props.updateFileState(file, this.state.initialFileState);
    window.removeEventListener('keydown', this.onKeyDown, true)
  }

  onCloseStateDialogue = e => {
    e.preventDefault();
    e.stopPropagation();
    this.props.closeStateDialogue();
    window.removeEventListener('keydown', this.onKeyDown, true)

  }

  changeFileState = e => {
    let file = { ...this.state.file };
    file[e.target.name] = e.target.value;
    if (file.reject_reason_other && !file.reject_reason) file.reject_reason = 'other';
    this.setState({ file });
  }

  setRejectReason = e => {
    let file = { ...this.state.file };
    file.reject_reason = e.target.id;
    this.setState({ file });
  }

  onKeyDown = e => {
    if (!this.isDisabled(this.state.file) && e.code === 'Enter' && e.ctrlKey) this.onSetFileState(e);
  }

  isDisabled = file => {
    return file.state === 'R' && (!file.reject_reason || (file.reject_reason === 'other' && !file.reject_reason_other.trim()));
  }

  render() {
    let { visible, top, left, oneRowDisplay } = this.props;
    let { file } = this.state;
    let isReadOnly = this.props.file.state === 'C' || this.props.file.state === 'R';
    let style = {
      display: visible ? 'block' : 'none',
      top,
      left
    };
    let rejectReasonsStyle = { display: this.state.file.state === 'R' ? 'block' : 'none' }
    let rejectReasonsClass = oneRowDisplay ? "one-row" : "reject-reasons-body";
    let cleaned = (
      (this.props.isScreenCapture ? this.props.scrCanCleanOrReject : true) ?
        (
          <FormControl>
            <input type="radio" name="state" value="C" id="c" checked={this.state.file.state === 'C'} disabled={isReadOnly} onChange={this.changeFileState} />
            <Label htmlFor="c">cleaned</Label>
          </FormControl>
        ) : null
    )
    return (
      <div className="file-state-dialogue" style={style}>
        <div className="fsd-title">
          <Label>Set file state:</Label>
        </div>

        <div className="fsd-body">
          {
            this.state.initialFileState === 'C' || this.state.initialFileState === "R" ?
              (
                <FormControl>
                  <input type="radio" name="state" value="M" id="m" checked={this.state.file.state === 'M'} onChange={this.changeFileState} />
                  <Label htmlFor="m">modified</Label>
                </FormControl>
              ) : null
          }
          {cleaned}
          {
            (this.props.isScreenCapture ? this.props.scrCanCleanOrReject : true) ?
              <>
                <FormControl>
                  <input type="radio" name="state" value="R" id="r" checked={this.state.file.state === 'R'} disabled={isReadOnly} onChange={this.changeFileState} />
                  <Label htmlFor="r">rejected</Label>
                </FormControl>

                <div className="reject-reasons" style={rejectReasonsStyle}>
                  <div className="reject-reasons-title">
                    <Label>Reject reason(s): </Label>
                  </div>
                  <div className={rejectReasonsClass}>

                    {
                      this.rejectReasons.map((reason, i) => {
                        return (
                          <FormControl key={i}>
                            <input type="radio" name="reject-reason" id={reason} disabled={isReadOnly} checked={file.reject_reason === reason} onChange={this.setRejectReason} />
                            <Label htmlFor={reason}>{reason}</Label>
                          </FormControl>
                        )
                      })
                    }
                  </div>

                  <div className="reject-reasons-title">
                    <Label>Other: </Label>
                  </div>
                  <div className="reject-reasons-body">
                    <FormControl>
                      <textarea disabled={isReadOnly} value={this.state.file.reject_reason_other} name="reject_reason_other" onChange={this.changeFileState} />
                    </FormControl>
                  </div>
                </div>
              </>
              : null
          }
        </div>

        <div className="fsd-footer">
          <Button className="btn-sm" onClick={this.onCloseStateDialogue}>Cancel</Button>
          <Button className="btn--primary btn-sm" onClick={this.onSetFileState} disabled={this.isDisabled(this.state.file)}>Apply</Button>
        </div>
      </div>
    );
  };


  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.file) !== JSON.stringify(this.props.file)) {
      this.setState({ file: JSON.parse(JSON.stringify(this.props.file)), initialFileState: this.props.file.state });
      window.addEventListener('keyup', this.onKeyDown)
    }
  };

}
