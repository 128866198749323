/**
 * Removes the element with the given index from the array.
 *
 * @param {Array} arr
 * @param {number} i
 * @return {Array}
 */
export const removeAt = (arr, i) => {
  return [
    ...arr.slice(0, i),
    ...arr.slice(i + 1),
  ];
};

/**
 * Inserts the element at the given index.
 *
 * @param {Array} arr
 * @param {any} item
 * @param {number} i
 * @return {Array}
 */
export const insertAt = (arr, item, i) => {
  return [
    ...arr.slice(0, i),
    item,
    ...arr.slice(i + 1),
  ];
};

/**
 * Checks for errors on the response object
 *
 * @param {Response} res
 */
export const checkError = (res) => {
  if (res.error) {
    throw new Error(res.error.message);
  }
  return res;
};

/**
 * Maps over object the given object, and applies the mapping
 * function to each key/value pair.
 *
 * @param {Object} obj
 * @param {Function} fn
 * @return {Object}
 */
export const mapObject = (obj, fn) => {
  return Object.keys(obj).reduce((acc, key) => ({
    ...acc,
    [key]: fn(key, obj[key]),
  }), {});
};

/**
 * Generates an array populated with numbers between a and b.
 *
 * @param {number} a
 * @param {number} b
 * @return {number[]}
 */
export const range = (a, b) => {
  const res = [];
  const min = Math.min(a, b);
  const max = Math.max(a, b) + 1;

  for (let i = min; i < max; i++){
    res.push(i);
  }

  return res;
};

export const findIndexByKey = (arr, val, key) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === val) return i;
  }
  return -1;
};

/**
 * Clamps the given number.
 *
 * @param {number} value value to clamp.
 * @param {number} min lower bound.
 * @param {number} max upper bound.
 * @return {number}
 */
export const clamp = (value, min, max) => Math.max(min, Math.min(value, max));

export const debounce = (func, wait, immediate) => {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};
