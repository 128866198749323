import React from 'react';
import classnames from 'classnames';

const Select = ({
  className,
  ...props
}) => (
  <select className={classnames('input-field', {[className]: className})}
    {...props}
  />
);

export default Select;
