export const timeline = {
  list: [],

  emotionsMap: ['neutral', 'happy', 'surprised', 'interested', 'confused', 'disgusted', 'afraid', 'sad'],

  getDefault() {
    return {
      id: Date.now(),
      neutral: 1,
      happy: 0,
      surprised: 0,
      interested: 0,
      confused: 0,
      disgusted: 0,
      afraid: 0,
      sad: 0,
      sawTheAd: 1,
      unmute: 0,
      zoom: 0,
      skipped: 0,
      index: 1
    }
  },

  initList(list) {
    list = list ? JSON.parse(list) : [this.getDefault()];
    this.list = list;
  },

  isListValid(list) {
    for (let i = 0; i < list.length; i++){
      if (!this.isBlockValid(list[i])) return false;
    }
    return true;
  },

  isBlockValid(block) {
    return block.sawTheAd ? this.isValidSeen(block) : this.isValidNotSeen(block);
  },

  isValidSeen(block) {
    for (let i = 0; i < this.emotionsMap.length; i++) {
      if (block[this.emotionsMap[i]]) return true;
    }
    return false;
  },

  isValidNotSeen(block) {
    for (let i = 0; i < this.emotionsMap.length; i++) {
      if (block[this.emotionsMap[i]]) return false;
    }
    return true;
  },

  addBlock(list, i) {
    let newBlock = this.getDefault();
    list.splice(i + 1, 0, newBlock);
    list = this.updateIndexes(list);
    return list;
  },

  removeBlock(list, idx) {
    if (list.length === 1) return list;
    list.splice(idx, 1);
    list = this.updateIndexes(list);
    return list;
  },

  updateIndexes(list) {
    list.forEach((el, idx) => el.index = idx + 1);
    return list;
  },

  onChangeData(idx, dataName, isDataChecked, list) {
    list[idx][dataName] = isDataChecked;
    if (dataName === 'sawTheAd' && !isDataChecked) {
      list[idx] = this.uncheckAllEmotionsAndInteractions(list[idx]);
    } else if (dataName === 'neutral' && isDataChecked) {
      list[idx] = this.checkOnlyNeutral(list[idx]);
    }
    return list;
  },

  checkOnlyNeutral(obj) {
    for (let i = 1; i < this.emotionsMap.length; i++) {
      obj[this.emotionsMap[i]] = 0;
    }
    return obj;
  },

  uncheckAllEmotionsAndInteractions(obj) {
    for (let key in obj) {
      if (key !== 'id' && key !== 'index')
        obj[key] = 0;
    }
    return obj;
  }
}
