import React from 'react';

const ToolbarSection = ({
  align,
  ...props
}) => (
  <section className={`toolbar_section toolbar_section--align-${align}`} {...props} />
);

ToolbarSection.defaultProps = {
  align: 'center',
};

export default ToolbarSection;
