import React, {Component} from 'react';
import {InputNumber, Button} from './common';

class VideoLengthForm extends Component {
  state = {
    value: this.props.value,
    updated: false,
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    if (this.state.value === '') {
      return;
    }

    this.props.onSubmit(this.state.value);
    this.setState({
      updated: true,
    });

    setTimeout(() => {
      this.setState({
        updated: false,
      })
    }, 3000);
  };

  render() {
    return (
      <form
        style={{
          marginBottom: 8,
        }}
      >
        <div style={{
          display: 'flex',
          alignItems: 'center',
        }}>
          <InputNumber
            value={this.props.val}
            onChange={this.onChange}
            disabled
          />
        </div>
        <div style={{
          fontSize: 10,
          fontWeight: 'normal',
          padding: '0',
        }}>{this.state.updated && 'Video length updated.'}</div>
      </form>
    );
  }
};

export default VideoLengthForm;
