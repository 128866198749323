import React, { Component } from 'react';
import { reducer } from './reducer';
import Panel from '../../components/panel';
import { Preloader } from '../../components/common';
import { api } from '../../api';
import emitter from '../../emitter';
import { timeline } from '../../store/timeline';

export default class extends Component {
  state = reducer.init();

  componentDidMount() {
    this.getData();
    document.addEventListener("keydown", this.onKeyDown);
  }

  onTimeUpdate = currentTime => {
    this.setState(reducer.updateTime(currentTime));
  };

  onKeyDown = e => {
    let data = {
      neutral: 0,
      happy: 0,
      surprised: 0,
      // puzzled: 0,
      disgust: 0,
      afraid: 0,
      sad: 0,
      interested: 0,
      confused: 0
    }
    let shortcuts = {
      N: 'neutral',
      H: 'happy',
      S: 'surprised',
      // P: 'puzzled',
      D: 'disgust',
      A: 'afraid',
      T: 'sad',
      I: 'interested',
      C: 'confused'
    }
    if (e.shiftKey && shortcuts[e.key]) {
      data = {
        ...data,
        [shortcuts[e.key]]: 1
      }
      this.onEdit(data);
    }
  }

  onPause = () => {
    this.setState(reducer.pauseVideo());
  };

  onPlay = () => {
    this.setState(reducer.playVideo());
    // this.deselectAll();
  };

  onSelect = (entry) => {
    this.setState(reducer.toggleEntry(entry));
  };

  onSelectRange = (e1, e2) => {
    this.setState(reducer.selectRange(e1, e2));
  };

  onEdit = data => {
    this.setState(reducer.editEntries(data), () => {
      this.onSave();
    });
  };

  onCancel = () => {
    this.setState(reducer.deselectAll());
  };

  deselectAll = () => {
    this.setState(reducer.deselectAll());
  };

  getParams = () => {
    return {
      project: this.props.filepath.project,
      cell: this.props.filepath.cell,
      task: this.props.filepath.task,
      stimuli: this.props.filepath.stimulus,
      scr: this.props.isScreenCapture,
      tester_id: this.props.filepath.userid.slice(0, this.props.filepath.userid.length - 4),
      worker_id:this.props.currWorkerID
    };
  };

  showSpinner = () => {
    let spinnerEl = document.querySelector(".preloader.fullscreen");
    let panelEl = document.querySelector(".panel");
    panelEl.style.opacity = 0;
    spinnerEl.classList.add("absolute");
    spinnerEl.classList.remove("invisible");
  };

  hideSpinner = () => {
    let spinnerEl = document.querySelector(".preloader.fullscreen");
    let panelEl = document.querySelector(".panel");
    spinnerEl.classList.add("invisible");
    spinnerEl.classList.remove("absolute");
    panelEl.style.opacity = 1;
  };

  getData = () => {
    this.showSpinner();
    let params = this.getParams();
    params.worker_id = this.props.currWorkerID;
    api.getFile(params)
      .then(response => {
        window.worker_id = response.data.worker_id;
        this.setState(reducer.loadData(response.data));
        this.props.updateFPS(response.data.fps);
        this.props.updateTotalFrames(response.data.frames);
        if (this.props.isScreenCapture) {
          let scrCanCleanOrReject = !!response.data.screenData;
          timeline.initList(response.data.screenData);
          this.props.setTimelines(timeline.list);
          this.props.updateStimulusPath(response.data.screen);
          this.props.setScrCanCleanOrReject(scrCanCleanOrReject);
        }
        this.hideSpinner();
      })
      .catch((err) => {
        this.props.onMessage(err.message);
      });
  };

  onSave = (timelines = []) => {
    const data = this.state.data.copy.map((entry, i) => {
      return {
        ...entry,
      };
    });
    let params = this.getParams();
    params.file = this.props.isScreenCapture ? timelines : data;
    this.props.setTimelines(timelines);

    return api.updateFile(params)
      .then((res) => {
        this.props.onSave();
        this.setState(reducer.saveData());
        return Promise.resolve();
      })
      .catch((err) => {
        this.props.onMessage(err.message);
      });
  };

  onUndo = () => {
    this.setState(reducer.undoData());
  };

  setRange = (range) => {
    this.setState({ range });
  }

  generateProps = () => {
    const { data, selected, range } = this.state;
    return {
      ...this.props,
      ...this.state,
      onTimeUpdate: this.onTimeUpdate,
      onSelect: this.onSelect,
      onSelectRange: this.onSelectRange,
      onEdit: this.onEdit,
      onCancel: this.onCancel,
      onNormalize: this.onNormalize,
      onSave: this.onSave,
      onUndo: this.onUndo,
      onPlay: this.onPlay,
      onPause: this.onPause,
      deselectAll: this.deselectAll,
      setRange: this.setRange,
      chartData: data.copy.map((e) => ({
        ...e,
        selected: (selected.includes(e.t)),
      })),
      editorData: data.copy.filter(({ t }) => (
        selected.includes(t)
      )).map(({ t, ...entry }) => entry),
      saveTimeline: this.saveTimeline
    };
  };

  saveTimeline = (data) => {
    this.onSave(data).then(() => this.props.setScrCanCleanOrReject(true));
  }

  render() {
    const props = this.generateProps();
    return (
      <>
        <Panel {...props} />
        <Preloader size="fullscreen" />
      </>
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filepath.userid !== this.props.filepath.userid || (this.props.toggleFile !== prevProps.toggleFile)) {
      this.getData();
    }
    if (this.props.filepath.name !== prevProps.filepath.name) {
      this.deselectAll();
      emitter.emit('videoChanged');
      this.setRange({
        from: 0,
        to: 50
      })
    }
  }
}
