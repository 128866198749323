import React, {Fragment, Component} from 'react';
import Homepage from '../homepage/Homepage.jsx';
import {Preloader} from '../../components/common';
import Toast from '../../components/Toast';
import Spinner from '../../components/spinner/Spinner.jsx';
import {auth} from '../../utils/auth.js';

class App extends Component {
  state = {
    selectedDir: null,
    isLoggedIn: false,
    messages: []
  }

  onMessage = msg => {
    this.setState({
      messages: this.state.messages.concat(msg),
    });
  }

  render() {
    return (
      <Fragment>
        {
          this.state.isLoggedIn ?
          <Homepage selectedDir={this.state.selectedDir}/>
            :
          <Spinner />
        }
        <div className="toast-container">
          {
            this.state.messages.map((message, i) =>
              <Toast
                key={i}
                message={message}
              />)
          }
        </div>
      </Fragment>
    );
  }

  componentDidMount() {
    auth.checkUser()
    .then(response => {
      auth.login(response.user_email, response.fb)
        .then(response => {
          this.setState({isLoggedIn: true});
        })
        .catch(err => Promise.reject())
    })
    .catch(err => this.onMessage('Wrong credentials'));

    const urlParams = new URLSearchParams(window.location.search);
    let selectedDir = {
      project: urlParams.get('project'),
      cell: urlParams.get('cell'),
      task: urlParams.get('task'),
      stimulus: urlParams.get('stimulus')
    }
    if (selectedDir.project && selectedDir.cell && selectedDir.task && selectedDir.stimulus)
      this.setState({selectedDir});
    auth.listenToChange();
  }
}

export default App;
