import React from 'react';

const Icon = ({
  icon,
  size,
}) => (
  <svg className='icon'
    width={`${size}px`}
    height={`${size}px`}
    viewBox='0 0 24 24'
  >
    <path d={icon} />
  </svg>
);

Icon.defaultProps = {
  size: 18,
};

export default Icon;
