import emitter from '../../emitter';
import {debounce} from '../../utils/utils.js'

export default function draggable(el, cb = null, options = {}) {
  let pos1 = 0;
  let pos2 = 0;
  el.onmousedown = debounce(startDragging, 10);

  function startDragging(e) {
    e = e || window.event;
    e.preventDefault();
    pos2 = e.clientX;
    window.document.onmouseup = stopDragging;
    window.document.onmousemove = drag;
    if (cb && cb.onStartDragging) cb.onStartDragging(e);
  };

  function drag(e) {
    e = e || window.event;
    e.preventDefault();
    let preventDrag = false
    if (cb && cb.preventDrag) {
      preventDrag = cb.preventDrag(e);
    }
    if (preventDrag) return;
    pos1 = pos2 - e.clientX;
    pos2 = e.clientX;
    if (options.limit && (el.offsetLeft - pos1 <= options.limit.from() || el.offsetLeft - pos1 >= options.limit.to()) ) return;
    el.style.left = (el.offsetLeft - pos1) + "px";
    if (cb && cb.onDrag) cb.onDrag();
  };

  function stopDragging(e) {
    document.onmouseup = null;
    document.onmousemove = null;
    if (cb && cb.onStopDragging) cb.onStopDragging(e, el);
  };
}
