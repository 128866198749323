import React, {Component, Fragment} from 'react';
import draggable from './draggable.js';
import emitter from '../../emitter';

export default class Slider extends Component {
  componentDidMount() {
    let cbs = {
      onStartDragging: this.onStartDragging,
      onDrag: this.onDragRange,
      onStopDragging: this.onStopDragging
    };

    draggable(this.range, cbs, {limit: {from: () => 3, to: this.calcLimitRange}});
    emitter.on('videoChanged', this.reset);
    document.onwheel = e => this.zoom(e);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.dots !== this.props.dots) {
      this.calcInitialStyle(this.props.range);
    }
    if (prevProps.currentFrame !== this.props.currentFrame) {
      this.onFrameUpdate(this.props.currentFrame)
    }
  };

  componentWillUnmount() {
    emitter.off('videoChanged', this.reset);
    document.onwheel = null;
  };

  zoom = e => {
    if (!e.shiftKey) return;
    if (e.deltaY > 0)
      this.zoomOut();
    else
      this.zoomIn();
  };

  zoomOut = () => {
    let range = {...this.props.range};
    range.to -= 50;
    if (range.to - range.from < 50) return;
    this.calcStyle(range);
    this.props.zoomChart(range);
    emitter.emit("recalcMarkersPosition");
  };

  zoomIn = () => {
    let range = {...this.props.range};
    let limitTo = range.to + 50;
    if (limitTo - range.from > 500) limitTo = range.from + 500;
    range.to = limitTo < this.props.dots ? limitTo : this.props.dots - 1;
    this.calcStyle(range);
    this.props.zoomChart(range);
    emitter.emit("recalcMarkersPosition");
  };

  calcInitialStyle = range => {
    let rangeWidth = (range.to - range.from) * this.getTickWidth();
    this.range.style.width = rangeWidth +  'px';
    this.to.style.left = rangeWidth + (this.to.getBoundingClientRect().width) + 'px';
  };

  calcStyle = (range) => {
    let sliderWidth = this.getSliderWidth();
    let borderWidth = 2 * this.from.getBoundingClientRect().width;
    let rangeWidth = (range.to - range.from) * this.getTickWidth();
    if (rangeWidth + borderWidth >= sliderWidth) rangeWidth = sliderWidth - borderWidth - 2.5;
    let offsetLeft = parseFloat(this.from.style.left) || 0;
    let toLeft = offsetLeft + rangeWidth + (this.to.getBoundingClientRect().width);
    let maxLeft = document.getElementsByClassName("recharts-area")[0].getBoundingClientRect().width;
    if (toLeft > maxLeft) {
       rangeWidth -= (toLeft - maxLeft);
       toLeft = maxLeft;
    }
    this.range.style.width = rangeWidth +  'px';
    this.to.style.left =  toLeft + 'px';
  };

  getSliderWidth = () => {
    return document.getElementById('slider').getBoundingClientRect().width;
  };

  getTickWidth = () => {
    return this.getSliderWidth() / this.props.dots;
  };

  reset = () => {
    document.getElementsByClassName('from')[0].style.left = '';
    document.getElementsByClassName('range')[0].style.left = '';
    document.getElementsByClassName('range')[0].style.width = '195px';
    document.getElementsByClassName('to')[0].style.left = '';
  };

  adjustRange = (currFrame, diff, step) => {
    if ((currFrame + step) >= this.props.range.to && currFrame !== 0) {
      let leftToEnd = this.props.dots - currFrame;
      let to = (currFrame + step < this.props.dots) ? currFrame + step : (currFrame + leftToEnd);
      let range = {from: to - diff, to};
      this.props.zoomChart(range);
      this.moveSlider(range);
    }

    if (currFrame -1 < this.props.range.from && currFrame !== 0) {
      let fromRange = currFrame - 1 > 1 ? currFrame - 1 : 1;
      let range = {from: fromRange, to: fromRange + diff};
      this.props.zoomChart(range);
      this.moveSlider(range);
    }
  };

  onFrameUpdate = currFrame => {
    let diff = this.props.range.to - this.props.range.from;
    let step = 1;

    if (window.isPlaying) {
      step = Math.floor(diff / 2);
    }

    this.adjustRange(currFrame, diff, step);
  };

  moveSlider = range => {
    let w = document.getElementById('slider').getBoundingClientRect().width;
    let dotWidth = w / this.props.dots;
    this.from.style.left = (range.from * dotWidth - 5) + 'px';
    this.to.style.left = (range.to * dotWidth - 5) + 'px';
    this.range.style.left = parseFloat(this.from.style.left) + 5 + 'px';
    this.range.style.width = parseFloat(this.to.style.left) - parseFloat(this.from.style.left) - 5 + 'px';
  }

  onStartDragging = () => {
  //  this.props.deselectAll();
    emitter.emit("hideMarkers");
  };

  onDragRange = e => {
    this.from.style.left = parseInt(this.range.style.left) - this.to.getBoundingClientRect().width + 'px';
    this.to.style.left = this.range.getBoundingClientRect().width + parseInt(this.range.style.left) + 'px';
  };

  onStopDragging = () => {
    let dotFrom = parseFloat(this.from.style.left);
    // eslint-disable-next-line
    if (dotFrom !== dotFrom) dotFrom = 0;

    let to = parseFloat(this.to.style.left) + this.to.getBoundingClientRect().width;
    // eslint-disable-next-line
    if (to !== to) to = 0;
    let w = document.getElementById('slider').getBoundingClientRect().width;
    let dotWidth = w / this.props.dots;
    dotFrom /= dotWidth;
    if ( dotFrom < 0) {
      dotFrom = 0;
    }
    let range = {from: Math.round(dotFrom), to: Math.round(to / dotWidth)};
    if (range.to - range.from > 500) range.to = range.from + 500;
    // if ((range.to - range.from) % 50 !== 0) range.from = range.to - 50;
    this.props.zoomChart(range);
    // this.props.deselectAll();
    emitter.emit("recalcMarkersPosition");
  };

  calcLimitRange = () => {
    let sliderPos = document.getElementById('slider').getBoundingClientRect();
    let toPos = this.to.getBoundingClientRect();
    let rangePos = this.range.getBoundingClientRect();
    return sliderPos.width - rangePos.width - toPos.width;
  };

  render() {
    return (
      <Fragment>
        <div id="slider">
          <div className="range-wrapper" >
            <div className="from" ref={node => this.from = node}></div>
            <div className="range"  ref={node => this.range = node}></div>
            <div className="to" ref={node => this.to = node}></div>
          </div>
        </div>
        <div className="range-info">{this.props.range.from} - {this.props.range.to} </div>
      </Fragment>
    )
  }
}
