import React, { Component } from 'react';
import { Area, AreaChart, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import Slider from './Slider.jsx';
import Legend from './legend';
import { Button, IconButton } from '../common';
import Markers from './Markers.jsx';
import { timeDot } from './timeDot.js';
import { labels } from './labels';
import { removeAt, findIndexByKey } from '../../utils/utils';
import emitter from '../../emitter'

export default class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: [],
      filterEmotions: [],
      showFrames: false,
      chartHeight: 120,
      marker1: { visibility: 'visible', frame: 1 },
      marker2: { visibility: 'visible', frame: 1 }
    };

    this.chart = React.createRef();
  };

  applyFilter = (filter) => {
    this.setState({
      filterEmotions: this.state.filterEmotions.concat(filter),
    });
  };

  removeFilter = (filter) => {
    const index = this.state.filterEmotions.indexOf(filter);
    this.setState({
      filterEmotions: removeAt(this.state.filterEmotions, index),
    });
  };

  onFilterEmotions = ({ value: filter }) => {
    const isApplied = this.state.filterEmotions.includes(filter);
    if (isApplied) {
      this.removeFilter(filter);
      return;
    }
    this.applyFilter(filter);
  };

  selectFrame = e => {
    if (!e) return;
    this.props.updateCurrentFrame(e.activeLabel);
    this.props.onSelect(this.findFrameOnChart(e.activeLabel))
    timeDot.setPosition(e.activeCoordinate.x);
  };

  findFrameOnChart = chartLabel => {
    if (!this.props.data[chartLabel - 1]) return;
    if (this.props.data[chartLabel - 1].t === chartLabel) {
      return this.props.data[chartLabel - 1];
    }
    return this.props.data.find(d => d.t === chartLabel);
  }

  onFrameUpdate = () => {
    let el = this.chart.current.state.tooltipTicks.find(el => el.value === this.props.currentFrame);
    if (!el) {
      timeDot.hide();
      return;
    };
    let position = el.coordinate;
    timeDot.show();
    if (position !== timeDot.position) {
      timeDot.setPosition(position);
    }
  };

  zoomChart = range => {
    this.props.setRange(range);
  };

  findNearestCoordinate = (el, arr) => {
    let nearestEl = 0;
    let minDiff = Number.MAX_SAFE_INTEGER;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].coordinate === el) return arr[i]
      if (arr[i].coordinate > el) return nearestEl;
      let currDiff = el - arr[i].coordinate;
      if (currDiff < minDiff) {
        minDiff = currDiff;
        nearestEl = arr[i];
      }
      if (currDiff < 0) return nearestEl;
    }
    return nearestEl;
  };

  onToggle = () => {
    this.onFrameUpdate();

    let data = this.chart.current.state.tooltipTicks;
    let dotFromCorrespondingTick = data.find(el => el.value === this.state.marker1.frame);
    let dotToCorrespondingTick = data.find(el => el.value === this.state.marker2.frame);

    if (dotFromCorrespondingTick) {
      let dotFromPosition = dotFromCorrespondingTick.coordinate;
      document.getElementById("dot-from").style.left = (dotFromPosition - 15 / 2) + 'px';
    }

    if (dotToCorrespondingTick) {
      let dotToPosition = dotToCorrespondingTick.coordinate;
      document.getElementById("dot-to").style.left = (dotToPosition - 15 / 2) + 'px';
    }
  };

  moveMarker1 = markerFromPos => {
    let data = this.chart.current.state.tooltipTicks;
    let marker1 = { frame: this.findNearestCoordinate(markerFromPos, data).value };
    this.props.onSelectRange(marker1.frame, this.state.marker2.frame);
    this.setState({
      marker1
    });
  };

  moveMarker2 = markerToPos => {
    let data = this.chart.current.state.tooltipTicks;
    let marker2 = { frame: this.findNearestCoordinate(markerToPos, data).value };
    this.props.onSelectRange(this.state.marker1.frame, marker2.frame);
    this.setState({
      marker2
    });
  };

  dropMarker = (markerEl, frame) => {
    if (!this.chart.current) return;
    let data = this.chart.current.state.tooltipTicks;
    let markerPos;
    let correspondingTick = data.find(el => el.value === frame);
    if (!correspondingTick) markerPos = 64;
    else markerPos = correspondingTick.coordinate - 15 / 2;
    markerEl.style.left = markerPos + 'px';
  };

  getTickWidth = () => {
    let chartEl = document.querySelector(".recharts-layer.recharts-area");
    return chartEl.getBoundingClientRect().width / (this.props.range.to - this.props.range.from);
  };

  adjustMarkers = () => {
    let data = this.chart.current.state.tooltipTicks;
    let currFrame = this.props.currentFrame;
    let chartEl = document.querySelector(".recharts-layer.recharts-area");

    let el1 = data.findIndex(el => el.value === currFrame);
    if (el1 < 0 || !chartEl) return;

    let marker1Pos = data[el1].coordinate;
    let marker1Frame = data[el1].value;
    let tickWidth = this.getTickWidth();
    let marker2Pos, marker2Frame;

    if (marker1Frame + 1 < this.props.data.length) {
      marker2Frame = marker1Frame + 1;
      marker2Pos = marker1Pos + tickWidth;
    } else {
      marker2Frame = marker1Frame;
      marker2Pos = marker1Pos;
    }
    document.getElementById("dot-from").style.left = (marker1Pos - 15 / 2) + 'px';
    document.getElementById("dot-to").style.left = (marker2Pos - 15 / 2) + 'px';
    document.getElementById("dot-from").style.visibility = "visible";
    document.getElementById("dot-to").style.visibility = "visible";
    this.setState({
      marker1: { frame: marker1Frame },
      marker2: { frame: marker2Frame }
    });
    this.props.onSelectRange(marker1Frame, marker2Frame);
  }

  componentDidMount() {
    emitter.on("toggle", this.onToggle);
  };

  componentWillUnmount() {
    emitter.off("toggle", this.onToggle);
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.data.length && this.props.data.length) {
      this.setState({ labels: labels.init(this.props.data) });
    }

    if (prevProps.currentFrame !== this.props.currentFrame) {
      this.onFrameUpdate();
    }

    if ((prevProps.range.from !== this.props.range.from) || (prevProps.range.to !== this.props.range.to)) {
      this.onFrameUpdate();
    }
  };


  setHeight = () => {
    let chartHeight = this.state.chartHeight === 120 ? 200 : 120;
    this.setState({ chartHeight });
  }

  updateMarkersFrames = (marker1Frame, marker2Frame) => {
    this.setState({
      marker1: { frame: marker1Frame },
      marker2: { frame: marker2Frame }
    });
  };

  updateMarkerFrame = (marker, frame) => {
    this.setState({
      [marker]: { frame }
    });
  }

  tickFormatter = num => {
    let fps = Number(this.props.fps);
    if (this.state.showFrames)
      return num;
    return (num / fps).toFixed(2);
  }

  toggleShowFrames = () => {
    this.setState({ showFrames: !this.state.showFrames });
  }

  render() {
    const { data, isPlaying, range } = this.props;
    let filteredData = data.filter(el => el.t <= range.to && el.t >= range.from).map(el => {
      let obj = { ...el };
      for (let key in el) {
        if (this.state.filterEmotions.indexOf(key) >= 0) delete obj[key];
      }
      return obj;
    });

    return (
      <div className="chart-wrapper">
        <div className="upper-chart">
          <Legend labels={this.state.labels} onClick={this.onFilterEmotions} filterEmotions={this.state.filterEmotions} />
          <div>
            <Button className="sm fs-toggle" onClick={this.toggleShowFrames}>
              <span>
                {
                  this.state.showFrames ?
                    'Show seconds' : 'Show frames'
                }
              </span>
            </Button>
            <Button className="sm eyebtn-sm" onClick={this.adjustMarkers}>Adjust markers</Button>
            <IconButton className="chart-height-btn" onClick={this.setHeight}>
              {
                this.state.chartHeight === 120 ?
                  <i className="material-icons" title="Expand chart"> keyboard_arrow_down </i>
                  :
                  <i className="material-icons" title="Collapse chart"> keyboard_arrow_up </i>
              }
            </IconButton>
          </div>
        </div>

        <div className="chart">
          <div className="chart_wrap">
            <ResponsiveContainer width={'100%'} height={this.state.chartHeight}>
              <AreaChart data={filteredData} onClick={this.selectFrame} ref={this.chart}>
                <XAxis dataKey='t' tickFormatter={this.tickFormatter} tickCount={10} />
                <YAxis type="number" domain={[0, 1]} />
                <CartesianGrid strokeDasharray='3 3' vertical={false} />
                {
                  this.state.labels.map((label, i) =>
                    <Area
                      type="monotone"
                      stackId='1'
                      key={label.value}
                      dataKey={label.value}
                      isAnimationActive={false}
                      fill={label.color}
                      stroke={label.color}
                      color={label.color}
                    />)
                }
                <line x1="65" y1="5" x2="65" y2={this.state.chartHeight === 120 ? 85 : 165} stroke="blue" strokeWidth={3} fill="blue" id="time-dot" />
              </AreaChart>
            </ResponsiveContainer>

            <Slider dots={this.props.data.length}
              zoomChart={this.zoomChart}
              range={this.props.range}
              deselectAll={this.props.deselectAll}
              currentFrame={this.props.currentFrame}
            />

            <Markers
              chartHeight={this.state.chartHeight}
              marker1={this.state.marker1}
              marker2={this.state.marker2}
              range={this.props.range}
              dropMarker={this.dropMarker}
              currentFrame={this.props.currentFrame}
              updateMarkerFrame={this.updateMarkerFrame}
              updateMarkersFrames={this.updateMarkersFrames}
              getTickWidth={this.getTickWidth}
              moveMarker1={this.moveMarker1}
              moveMarker2={this.moveMarker2}
              onSelectRange={this.props.onSelectRange}
            />
          </div>
        </div>
      </div>
    )
  }
}
