import React, { Component, Fragment } from 'react';
import File from './File.jsx';
import FileStateDialogue from '../modal/FileStateDialogue.jsx';
import ContextMenu from '../ContextMenu';
export default class Files extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contextMenuData: null,
      stateDialogue: { visible: false, top: 0, left: 0, file: {}, oneRowDisplay: false }
    }
  }

  openStateDialogue = (e, file, index) => {
    this.props.openFile(file);
    e.stopPropagation();
    if (file.state !== 'R' && file.state !== 'M' && !file.active) return;
    let oneRowDisplay = index >= this.props.data.length - 3;
    let stateDialogue = {
      visible: true,
      top: (e.clientY - 20) + 'px',
      left: (e.clientX + 35) + 'px',
      file,
      oneRowDisplay
    }
    window.preventPlaying = true;
    this.setState({ stateDialogue });
  };

  closeStateDialogue = () => {
    let stateDialogue = {
      visible: false,
      top: '0px',
      left: '0px',
      file: {},
      oneRowDisplay: false
    }
    window.preventPlaying = false;
    this.setState({ stateDialogue });
  }

  outsideCloseStateDialogue = e => {
    if (this.fsd && !this.fsd.contains(e.target)) this.closeStateDialogue();
  }

  updateFileState = (file, initialFileState) => {
    this.props.update(file, initialFileState);
    this.closeStateDialogue();
  }

  setStateTmp = e => {
    let stateDialogue = JSON.parse(JSON.stringify(this.state.stateDialogue));
    stateDialogue.file.state = 'C';
    this.setState({ stateDialogue });
  }

  toggleStarred = (e, file) => {
    e.stopPropagation();
    file.isStarred = !file.isStarred;
    this.props.update(file);
  }

  setContextMenuData = (contextMenuData) => {
    this.setState({ contextMenuData })
  }

  getContextMenuData = () => {
    return this.state.contextMenuData;
  }

  render() {
    return (
      <Fragment>
        <ContextMenu bindingEl=".explorer_item.explorer_item--file" content={<CopyFilenameToClipboard getContextMenuData={() => this.getContextMenuData()} />} setData={this.setContextMenuData} />

        <span ref={node => this.fsd = node}>
          <FileStateDialogue {...this.state.stateDialogue}
            closeStateDialogue={this.closeStateDialogue}
            updateFileState={this.updateFileState}
            setStateTmp={this.setStateTmp}
            isScreenCapture={this.props.isScreenCapture}
            scrCanCleanOrReject={this.props.scrCanCleanOrReject}
          />
        </span>

        <ul className='explorer_list'>
          {
            this.props.data.map((file, i) => (
              <File key={i}
                file={file}
                index={i}
                openFile={() => this.props.openFile(file)}
                openStateDialogue={this.openStateDialogue}
                opened={this.props.openedFile.id === file.id}
                disabled={file.active && this.props.openedFile.id !== file.id}
                toggleStarred={this.toggleStarred} />
            ))
          }
        </ul>
      </Fragment>
    )
  }

  componentDidMount() {
    window.onclick = e => this.outsideCloseStateDialogue(e);
  }

  componentWillUnmount() {
    window.onclick = null;
  }
}

function CopyFilenameToClipboard({ getContextMenuData }) {
  function copy(e) {
    e.preventDefault();
    let el = document.getElementById('copy-to-clipboard');
    let fileName = getContextMenuData().slice(0, getContextMenuData().length - 4);
    el.value = fileName;
    el.select();
    document.execCommand("copy");
  }

  return (
    <div className="copy-filename" onClick={copy}>
      <i className="material-icons link">file_copy</i>
      <span>Copy</span>
    </div>
  )
}
