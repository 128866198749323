import React, { Component } from 'react';
import Respondent from './Respondent.jsx';
import Stimulus from './Stimulus.jsx';
import Controls from './Controls.jsx';
import moment from 'moment';
import emitter from '../../emitter.js';

export default class Videos extends Component {
  state = {
    isStimulusVisible: true,
    elapsedTime: '00:00',
    currentTime: '',
    playbackRate: 1,
    isPlaying: false
  };

  updateTime = currentTime => {
    let elapsedTime = moment(currentTime, "ss").format("mm:ss");
    this.setState({ elapsedTime, currentTime });
    if (!this.state.isPlaying) {
      emitter.emit("recalcMarkersPosition");
    }
  };

  toggleManualTimeUpdates = isPlaying => {
    let elems = [document.querySelector('.chart'), document.querySelector('.frame-ctrls'), document.querySelector('#respondent.video-js .vjs-progress-control.vjs-control')];

    for (let i = 0; i < elems.length; i++) {
      if (!elems[i]) return;
      if (isPlaying) {
        elems[i].classList.add('disabled-evts');
        continue;
      }
      elems[i].classList.remove('disabled-evts');
    }
  }


  updateIsPlaying = isPlaying => {
    window.isPlaying = isPlaying;

    this.toggleManualTimeUpdates(isPlaying);

    this.setState({ isPlaying });
    if (isPlaying) {
      emitter.emit("hideMarkers");
      return;
    }
    emitter.emit("recalcMarkersPosition");
  };

  toggleStimulusVisibility = () => {
    let isStimulusVisible = !this.state.isStimulusVisible;
    this.setState({
      isStimulusVisible
    });
    this.adjustDimensions(isStimulusVisible);
  };

  updatePlaybackRate = playbackRate => {
    this.setState({ playbackRate });
    this.props.setPlaybackRateAll(playbackRate);
  };

  calcMaxVideoWidth = isStimulusVisible => {
    let videoWrapperEl = document.getElementsByClassName('video-wrap')[0];
    let wrapperWidth = videoWrapperEl.getBoundingClientRect().width;
    return isStimulusVisible ? (wrapperWidth / 2 - 20) : wrapperWidth;
  };

  calcMaxVideoHeight = () => {
    let chartWrapperEl = document.getElementsByClassName("chart-wrapper")[0];
    let panelEl = document.getElementsByClassName("panel_main")[0];
    let videoBoxEl = document.getElementsByClassName("video-box")[0];
    let videoCtrlsEl = document.getElementsByClassName("video-controls-wrapper")[0];
    return panelEl.getBoundingClientRect().height - chartWrapperEl.offsetHeight - 170;
  };

  adjustDimensions = (isStimulusVisible = this.state.isStimulusVisible) => {
    let respondentEl = document.getElementById('respondent');
    let stimulusEl = document.getElementById('stimulus');
    let maxWidth = this.calcMaxVideoWidth(isStimulusVisible);
    let maxHeight = this.calcMaxVideoHeight();
    let ratio = 4 / 3;
    let proportionalHeight = maxWidth / ratio;

    if (!respondentEl) return;
    if (proportionalHeight > maxHeight) {
      respondentEl.style.width = maxHeight * ratio + 'px';
      respondentEl.style.height = maxHeight + 'px';
    } else {
      respondentEl.style.width = maxWidth + 'px';
      respondentEl.style.height = proportionalHeight + 'px';
    }

    if (!stimulusEl) return;
    stimulusEl.style.width = respondentEl.style.width;
    stimulusEl.style.height = respondentEl.style.height;
  };

  render() {
    return (
      <div className={'video-box' + this.props.isScreenCapture ? ' scr' : ''} id='media-materials'>
        <div className='video-wrap'>
          <Respondent
            src={this.props.respondentPath}
            updateCurrentFrame={this.props.updateCurrentFrame}
            fps={this.props.fps}
            updateTime={this.updateTime}
            updateIsPlaying={this.updateIsPlaying}
            updatePlaybackRate={this.updatePlaybackRate}
            isStimulusVisible={this.state.isStimulusVisible}
            playbackRateAll={this.props.playbackRateAll}
            jumpToTime={this.props.jumpToTime}
          />
          <Stimulus
            src={this.props.stimulusPath}
            currentTime={this.state.currentTime}
            isPlaying={this.state.isPlaying}
            playbackRate={this.state.playbackRate}
            isStimulusVisible={this.state.isStimulusVisible}
          />
        </div>

        <Controls
          fps={this.props.fps}
          totalFrames={this.props.totalFrames}
          currentFrame={this.props.currentFrame}
          updateCurrentFrame={this.props.updateCurrentFrame}
          elapsedTime={this.state.elapsedTime}
          isStimulusVisible={this.state.isStimulusVisible}
          toggleStimulusVisibility={this.toggleStimulusVisibility}
        />
      </div>
    )
  };

  componentDidMount() {
    emitter.on('toggle', this.adjustDimensions);
  }

};
