import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './containers/app';
import registerServiceWorker from './registerServiceWorker';
import {db} from './data/files.js';

window.FPS = 20;
db.initialize();
ReactDOM.render(
  <App />,
  document.getElementById('root')
);
registerServiceWorker();
