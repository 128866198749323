import React, { Component } from 'react';
import { Button } from '../common';
import { fileStates } from '../../utils/file-states.js';

export default class File extends Component {
  openActiveFileDialogue = (e, file, index) => {
    if (!file.state && file.active)
      this.props.openStateDialogue(e, file, index);
  }

  render() {
    let { file, openFile, openStateDialogue, opened, disabled, index, toggleStarred } = this.props;
    let classes = 'explorer_item explorer_item--file' + (opened ? ' explorer_item--open' : '') + (disabled ? ' disabled' : '');
    let activeFileView = file.active ?
      <Button style={{ background: '#ff9800' }}
        title="active"
        disabled={file.active && !opened}
        className="file-state-btn is-active-btn"
        onClick={e => this.openActiveFileDialogue(e, file, index)}
      >A</Button>
      : null;

    return (
      <li className={classes} onClick={openFile} data-contextmenudata={file.name}>
        <i className={"material-icons starred-file" + (file.isStarred ? " is-starred" : '')} onClick={e => toggleStarred(e, file)}>
          {file.isStarred ? 'star' : 'star_border'}
        </i>
        <span>{file.name}</span>
        <Button style={{ background: fileStates[file.state].color, display: file.state !== '' ? 'inline-flex' : 'none' }}
          className="file-state-btn"
          onClick={e => openStateDialogue(e, file, index)}>{file.state}</Button>
        {activeFileView}
      </li>
    );
  }

}
