import React, { Component } from 'react';
import ExplorerHeader from './header';
import Files from './Files.jsx';
import { Input, IconButton, Button, Preloader } from '../common';
import { db } from '../../data/files.js';
import { api } from '../../api';
import { fileStates } from '../../utils/file-states.js';
import emitter from '../../emitter.js';

class Explorer extends Component {
  state = {
    expanded: true,
    data: [],
    filteredData: [],
    searchFor: '',
    openedFile: { id: -1 },
    isPlaying: false,
    disableAll: false
  };

  onToggle = () => {
    this.setState((state) => ({
      expanded: !state.expanded
    }));
  }

  showFile = file => {
    return (this.state.searchFor === ":starred" ? file.isStarred : file.userid.toLowerCase().indexOf(this.state.searchFor) >= 0) && (this.props.filtered.indexOf(fileStates[file.state].state) >= 0)
  }

  setData = () => {
    db.getTestFiles(this.props.selectedDir)
      .then(allFiles => {
        let data = allFiles.filter(file => (this.state.searchFor === ":starred" ? file.isStarred : file.userid.toLowerCase().indexOf(this.state.searchFor) >= 0) && (this.props.filtered.indexOf(fileStates[file.state].state) >= 0))
          .map(file => ({ name: file.userid, ...file }));
        if (this.props.validationGroup !== 'validated') {
          this.setState({ data, filteredData: data });
        } else {
          let filteredData = [];
          outerLoop: for (let i = 0; i < this.props.validUsers.length; i++) {
            let currentData = data.map(d => d.name);
            for (let j = 0; j < currentData.length; j++) {
              if (currentData[j].indexOf(this.props.validUsers[i]) >= 0) {
                filteredData.push(data[j]);
                continue outerLoop;
              }
            }
          }
          this.setState({ data, filteredData });
        }
        this.updateCount(data);
      });
  };

  onClick = (evt) => {
    const { path } = evt.target.dataset;
    this.props.onClick(path);
  };

  updateCount = data => {
    if (this.props.validationGroup !== 'all') data = this.state.filteredData;
    let count = { total: 0, C: 0, R: 0 };
    data.forEach(file => {
      if (count[file.state])
        count[file.state]++;
      else {
        count[file.state] = 1;
      }
      count.total++;
    })
    this.props.onUpdateCount(count);
  }

  onFileUpdate = file => {
    let data = JSON.parse(JSON.stringify(this.state.data));
    let filteredData = JSON.parse(JSON.stringify(this.state.filteredData));
    for (let i = 0; i < data.length; i++) {
      if (data[i].name === file.userid) {
        data[i] = { ...data[i], ...file };
        let filteredIdx = filteredData.findIndex(el => el.name === file.userid);
        filteredData[filteredIdx] = file;
        this.setState({ data, filteredData });
        this.updateCount(data);
        return;
      }
    };
  };

  update = (file, initialFileState) => {
    let tester_id = file.userid.slice(0, file.userid.length - 4);
    let params = {
      ...this.props.selectedDir,
      worker_id: this.props.currWorkerID,
      file: '',
      rejected_reason: file.reject_reason || file.reject_reason_other,
      tester_id,
      scr: this.props.isScreenCapture
    };

    if (this.props.isScreenCapture) {
      if (!this.props.scrCanCleanOrReject && file.state === 'C') return;
      delete params.file;
      db.update(file.id, file);

      if (initialFileState === 'C' || initialFileState === 'R') {
        api.cleanedToModified(params);
        return;
      }
      if (file.state === 'R') {
        params.rejected_reason = file.reject_reason || file.reject_reason_other;
        api.markAsRejected(params);
      } else if (file.state === 'C')
        api.markAsCleaned(params);

      return;
    }

    if (initialFileState === 'C') {
      api.cleanedToModified(params)
        .then(response => {
          if (response.data.status === 400) return Promise.reject();
          db.update(file.id, file);
        })
        .catch(err => console.log(err));
      return;
    }

    db.update(file.id, file);

    api.updateFile(params)
      .then(response => {
        if (file.state === 'R') {
          params.rejected_reason = file.reject_reason || file.reject_reason_other;
          api.markAsRejected(params);
        } else if (file.state === 'C')
          api.markAsCleaned(params);
        this.props.updateReadOnly(file.state)
      })
  }

  openFile = file => {
    if (window.isPlaying || (file.active /*&& this.state.openedFile.id !== file.id) */)) return;
    this.closeFile(this.state.openedFile);
    if (file.id !== -1) {
      file.active = true;
      db.initStatusListener(file);
    }
    db.update(file.id, file);
    this.setState({ openedFile: file });
    window.openedFile = file;
    this.props.onClick(file);
  }

  closeFile = file => {
    if (file.id !== -1)
      db.update(file.id, { active: false });
  }

  searchFor = e => {
    let searchFor = e.target.value.toLowerCase().trim();
    // let data = JSON.parse(JSON.stringify(this.state.data));
    // let filteredData = data.filter(file => file.name.toLowerCase().indexOf(searchFor) >= 0 && (this.props.filtered.indexOf(fileStates[file.state].state) >= 0));
    this.setState({ searchFor });
    this.setData();
  }

  copyToClipboard = () => {
    let copyToClipboardEl = document.getElementById("copy-to-clipboard");
    let ids = this.state.filteredData.map(file => file.name.substring(0, file.name.length - 4)).join(',');
    copyToClipboardEl.value = ids;
    copyToClipboardEl.select();
    document.execCommand("copy");
  }

  allToCleaned = () => {
    let data = {
      ...this.props.selectedDir,
      scr: this.props.isScreenCapture,
      workers_id: window.workers.join(",")
    }
    api.cleanAll(data).then(response => {
      if (response.data.status === 400) return;
      let failed = response.data.failed;
      db.markAllCleaned(this.props.selectedDir, failed);
    });
  }

  allToNeutral = () => {
    let testers_id = [];
    db.getAllUntouched(this.props.selectedDir).then(files => {
      testers_id = files.filter(file => file.userid && file).map(file => file.userid.replace(/.csv/g, '')).join(', ');
      let data = {
        ...this.props.selectedDir,
        testers_id
      }
      this.setState({ disableAll: true });
      api.allToNeutral(data)
        .then(response => this.props.setToggleFile())
        .finally(() => this.setState({ disableAll: false }));
    });
  };

  onKeyDown = e => {
    if (e.altKey && e.key.toLowerCase() === 'n')
      this.allToNeutral();
    if (e.altKey && e.key.toLowerCase() === 'c' && this.state.openedFile.id !== -1) {
      this.update({...this.state.openedFile, state: 'C'}, this.state.openedFile);
    }
  }

  render() {
    const { expanded, isPlaying } = this.state;
    const explorerClasses = 'explorer' + (isPlaying ? ' disabled' : '');
    return (
      <div className={explorerClasses}>
        {
          this.state.disableAll ?
            <div className="disable-all">
              <Preloader />
            </div> : null
        }

        <ExplorerHeader
          name={this.props.selectedDir || 'data'}
          expanded={expanded}
          onClick={this.onToggle}
        />
        <span className="files-options">
          <Input className='file-search' type="text" placeholder="Search..." value={this.state.searchFor} onChange={this.searchFor} />
          <Button onClick={this.allToCleaned} className="file-state-btn" style={{ background: 'rgb(102, 187, 106)' }} title="Clean all">
            <span>C</span>
          </Button>
          <Button onClick={this.allToNeutral} className="file-state-btn" style={{ background: 'grey', display: this.props.isScreenCapture ? 'none' : 'block' }} title="All to neutral">
            <span>N</span>
          </Button>
          <IconButton onClick={this.copyToClipboard} title="Copy to clipboard">
            <i className="material-icons">file_copy</i>
          </IconButton>
          <input type="text" id="copy-to-clipboard" />
        </span>

        <Files data={this.state.filteredData}
          update={this.update}
          openFile={this.openFile}
          openedFile={this.state.openedFile}
          isScreenCapture={this.props.isScreenCapture}
          scrCanCleanOrReject={this.props.scrCanCleanOrReject}
        />
      </div>
    );
  };

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.selectedDir) !== JSON.stringify(this.props.selectedDir) || JSON.stringify(prevProps.filtered) !== JSON.stringify(this.props.filtered) || JSON.stringify(prevProps.docs) !== JSON.stringify(this.props.docs) || JSON.stringify(prevProps.validUsers) !== JSON.stringify(this.props.validUsers)) {
      this.setData();
    }
  }

  componentDidMount() {
    emitter.on('fileUpdated', this.onFileUpdate);
    emitter.on('playStateChange', isPlaying => this.setState({ isPlaying }));
    window.onbeforeunload = () => this.closeFile(this.state.openedFile);
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    emitter.off('fileUpdated', this.onFileUpdate);
    emitter.off('playStateChange', isPlaying => this.setState({ isPlaying }));
    document.removeEventListener('keydown', this.onKeyDown);
  }
}

export default Explorer;
