import React from 'react';
import Input from './Input';

const validate = (value) => (!value && value !== 0) ? '' : value;

const InputNumber = ({
  value,
  ...props
}) => (
  <Input
    type='number'
    value={validate(value)}
    {...props}
  />
);

export default InputNumber;
