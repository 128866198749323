import React from 'react';
import Button from './Button';
import classnames from 'classnames';

const IconButton = ({
  className,
  ...props
}) => (
  <Button className={classnames('icon-btn', {[className]: className})} {...props} />
);

export default IconButton;
