import React, {Fragment} from 'react';
import {IconButton} from '../../common';
import {auth} from '../../../utils/auth';

const PanelAside = (props) => (
  <Fragment>
    <aside className='panel_aside' {...props} />
    <IconButton style={{position: 'fixed', right: '20px', bottom: '20px'}} onClick={auth.logout} title="Log out">
      <i className="material-icons">power_settings_new</i>
    </IconButton>
  </Fragment>
);

export default PanelAside;
