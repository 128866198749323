import React from 'react';
import ToolbarRow from './row';
import ToolbarActions from './actions';
import ToolbarSection from './section';
import { Icon, IconButton, Button } from '../common';
import icons from '../../assets/icons';
import classnames from 'classnames';

const Toolbar = ({
  touched,
  setOneToNeutral,
  onSave,
  onClose,
  isReadOnly
}) => (
    <ToolbarRow>
      <ToolbarSection align='end'>
        <ToolbarActions style={{ flexGrow: 1 }}>
          <Button
            className='compact block'
            onClick={(setOneToNeutral)}
            disabled={isReadOnly}
          >all to neutral</Button>
        </ToolbarActions>
        <ToolbarActions>
          <IconButton
            className={classnames('toolbar_action-item', {
              'toolbar_action-item--touched': touched,
            })}
            title='Close panel'
            onClick={onClose}
          >
            <Icon icon={icons.CLOSE} />
          </IconButton>
        </ToolbarActions>
      </ToolbarSection>
    </ToolbarRow>
  );

export default Toolbar;
