import React from 'react';
import emitter from '../emitter.js';

export const toggler = {
  isExpanded: true,
  lastTransition: 1,
  expandableEl: null,
  affectedEl: null,

  init: function (expandableEl, affectedEl) {
    this.expandableEl = expandableEl;
    this.affectedEl = affectedEl;
    this.affectedEl.ontransitionend = () => {
      if (!!toggler.lastTransition !== toggler.isExpanded) {
        toggler.lastTransition = toggler.lastTransition ? 0 : 1;
        emitter.emit('toggle');
      }
    }
    window.togglerEl.onclick = this.toggle;
  },

  collapse: function() {
    this.affectedEl.style.paddingLeft = '15px';
    this.expandableEl.style.width = '0px';
    window.togglerEl.style.left = "0px";
    window.togglerIcon.innerText = "chevron_right";
  },

  expand: function () {
    let width = getComputedStyle(document.documentElement).getPropertyValue('--explorer-width');
    this.affectedEl.style.paddingLeft = width;
    this.expandableEl.style.width = width;
    window.togglerEl.style.left = `${parseInt(width) - 15}px`;
    window.togglerIcon.innerText = "chevron_left";
  },

  toggle: function () {
    toggler.isExpanded = !toggler.isExpanded;
    if (toggler.isExpanded) {
      toggler.expand();
    } else {
      toggler.collapse();
    }
  },

  render: function () {
    return (
      <div className="toggle-visibility" id="togglerEl">
        {
          <i className="material-icons link" id="togglerIcon">chevron_left</i>
        }
      </div>
    )
  }
}
