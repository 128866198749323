import React, {Component} from 'react';
import draggable from './draggable.js';
import emitter from '../../emitter.js';

export default class Markers extends Component {
  componentDidMount() {
    let from = document.getElementById('dot-from');
    let to = document.getElementById('dot-to');
    this.zeroPosition = 58;
    this.dotWidth = 15;
    let w = () => document.querySelector('.chart').getBoundingClientRect().width + this.zeroPosition - this.dotWidth/2 - 1;
    draggable(from,
      {
        preventDrag: this.preventDragDotFrom,
        onStopDragging: this.onStopDragging
      },
      { limit: {from: () => 58, to: () => w()}},
    );
    draggable(to,
      {
        preventDrag: this.preventDragDotTo,
        onStopDragging: this.onStopDragging
      },
      {limit: {from: () => 58, to:() =>  w()}}
    );

    document.addEventListener('keydown', this.dropMarkers)
    emitter.on("hideMarkers", this.hideMarkers);
    emitter.on("recalcMarkersPosition", this.recalcMarkersPosition);
    emitter.on("videoChanged", this.reset);
  };

  componentWillUnmount () {
    emitter.off("hideMarkers", this.hideMarkers);
    emitter.off("recalcMarkersPosition", this.recalcMarkersPosition);
  };

  reset = () => {
    if (!this.dotFromEl() || !this.dotToEl()) return;
    this.dotFromEl().style.visibility = 'visible';
    this.dotFromEl().style.left = this.zeroPosition + 'px';
    this.dotToEl().style.visibility = 'visible';
    this.dotToEl().style.left = this.zeroPosition + 'px';
    this.props.updateMarkersFrames(0, 0);
  };

  dropMarkers = e => {
    let {currentFrame, range} = this.props;
    let isFrameInRange = this.isInsideRange(currentFrame, range);

    if (e.shiftKey && e.code === "Digit1" && isFrameInRange) {
      this.props.dropMarker(this.dotFromEl(), currentFrame);
      this.props.updateMarkerFrame('marker1', currentFrame);
      this.showMarker(this.dotFromEl());

      if (currentFrame >= this.props.marker2.frame) {
        let nextFrame = this.isInsideRange((currentFrame + 1), range) ? (currentFrame + 1) : currentFrame;
        this.props.dropMarker(this.dotToEl(), nextFrame);
        this.props.updateMarkerFrame('marker2', nextFrame);
        this.showMarker(this.dotToEl());
        this.props.onSelectRange(currentFrame, nextFrame);
        return;
      }

      this.props.onSelectRange(currentFrame, this.props.marker2.frame);
    }

    if (e.shiftKey && e.code === "Digit2" && isFrameInRange) {
      this.props.dropMarker(this.dotToEl(), currentFrame);
      this.props.updateMarkerFrame('marker2', currentFrame);
      this.showMarker(this.dotToEl());

      if (currentFrame <= this.props.marker1.frame) {
        let prevFrame =  this.isInsideRange(currentFrame - 1, range) ? (currentFrame - 1) : currentFrame;
        this.props.dropMarker(this.dotFromEl(), prevFrame);
        this.props.updateMarkerFrame('marker1', prevFrame);
        this.showMarker(this.dotFromEl());
        this.props.onSelectRange(prevFrame, currentFrame);
        return;
      };

      this.props.onSelectRange(this.props.marker1.frame, currentFrame);
    }
  };

  dotFromEl = () => {
    return document.getElementById('dot-from');
  };

  dotToEl = () => {
    return document.getElementById('dot-to');
  };

  recalcMarkersPosition = () => {
    let dotFromEl = this.dotFromEl();
    let dotToEl = this.dotToEl();

    if (this.isInsideRange(this.props.marker1.frame, this.props.range)) {
      dotFromEl.style.visibility='visible';
      this.props.dropMarker(dotFromEl, this.props.marker1.frame);
    } else {
      dotFromEl.style.visibility='hidden';
    }

    if (this.isInsideRange(this.props.marker2.frame, this.props.range)) {
      dotToEl.style.visibility='visible';
      this.props.dropMarker(dotToEl, this.props.marker2.frame);
    } else {
      dotToEl.style.visibility='hidden';
    }
  };

  isInsideRange = (frame, range) => {
    return frame >= range.from && frame <= range.to;
  }

  hideMarkers = () => {
    this.dotToEl().style.visibility = "hidden";
    this.dotFromEl().style.visibility = "hidden";
  };

  showMarkers = () => {
    this.dotToEl().style.visibility = "visible";
    this.dotFromEl().style.visibility = "visible";
  };

  showMarker = el => {
    el.style.visibility = "visible";
  };

  preventDragDotFrom = e => {
    let preventDrag = false;
    let dotFromPosition = this.dotFromEl().getBoundingClientRect().left;
    preventDrag = (e.clientX > dotFromPosition && this.dotPrevents(e));
    return preventDrag;
  };

  preventDragDotTo = e => {
    let preventDrag = false;
    let dotToPosition = this.dotToEl().getBoundingClientRect().right;
    preventDrag = ((e.clientX < dotToPosition) && this.dotPrevents(e));
    return preventDrag;
  };

  dotPrevents = (e) => {
    let dotFromPosition = this.dotFromEl().getBoundingClientRect().left;
    let dotToPosition = this.dotToEl().getBoundingClientRect().left;
    return dotToPosition - dotFromPosition < this.props.getTickWidth();
  };

  calcRelativePosition = dotEl => {
    if(!dotEl || !dotEl.style) return;
    return dotEl.style.left ? (parseFloat(dotEl.style.left) + this.dotWidth/2 ) : (this.zeroPosition + this.dotWidth/2);
  };

  onStopDragging = (e, el) => {
    if (el.id === 'dot-from') {
      let dotFromRelativePositon = this.calcRelativePosition(this.dotFromEl());
      this.props.moveMarker1(dotFromRelativePositon);
    } else {
      let dotToRelativePositon = this.calcRelativePosition(this.dotToEl());
      this.props.moveMarker2(dotToRelativePositon);
    }
  };

  render () {
    let classes = "dot" + (this.props.chartHeight === 120 ? "" : " expanded");
    return (
      <span>
        <div id="dot-from" className={classes} ></div>
        <div id="dot-to" className={classes} ></div>
      </span>
    )
  }
}
