import axios from './config.js';

function getToken() {
  return window.localStorage.getItem('emo_user_key');
}

export const api = {
  userCheck() {
    return axios({
      url: '/user/check',
      method: "GET",
      params: {t: new URLSearchParams(window.location.search).get('t')}
    })
  },

  fetchProjects(params) {
    params = {...params, t: getToken()};
    return axios({
      url: '/projects',
      params
    });
  },

  fetchTesters(params) {
    params = {...params, t: getToken()};
    return axios({
      url: '/project/testers',
      params
    });
  },

  getFile(params) {
    params = {...params, t: getToken()};
    return axios({
      url: '/project/stimuli',
      params
    });
  },

  updateFile(data) {
    data = {...data, t: getToken()};
    return axios({
      url: '/project/stimuli',
      method: 'POST',
      data
    });
  },

  markAsCleaned(data) {
    data = {...data, t: getToken()};
    return axios({
      url: '/project/stimuli/cleaned',
      method: 'POST',
      data
    });
  },

  cleanedToModified(data) {
    data = {...data, t: getToken()};
    return axios({
      url: '/project/stimuli/revoke/cleaned',
      method: "POST",
      data
    });
  },

  cleanAll(data) {
    data = {...data, t: getToken()};
    return axios({
      url: '/project/stimuli/bulk/cleaned',
      method: "POST",
      data
    });
  },

  allToNeutral(data) {
    data = {...data, t: getToken()};
    return axios({
      url: '/project/stimuli/allNeutral',
      method: "POST",
      data
    });
  },

  oneToNeutral(data) {
    data = {...data, t: getToken()};
    return axios({
      url: '/project/stimuli/neutral',
      method: "POST",
      data
    });
  },

  markAsRejected(data) {
    data = {...data, t: getToken()};
    return axios({
      url: '/project/stimuli/rejected',
      method: 'POST',
      data
    });
  },

  export(params) {
    params = {...params, t: getToken()};
    return axios({
      url: '/project/export/csv',
      params,
      responseType:'arraybuffer'
    });
  },


  downloadVideos(params) {
    params = {...params, t: getToken()};
    return axios({
      url: '/project/export/videos',
      params,
      responseType:'arraybuffer'
    });
  }

}
