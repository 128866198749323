import React from 'react';
import classnames from 'classnames';
import ChartLegendLabelBox from './box';

const ChartLegendLabel = ({
  value,
  selected,
  onClick,
  color,
}) => (
  <li
    className={classnames('legend-item', {'legend-item--active': selected})}
    onClick={onClick}
  >
    <ChartLegendLabelBox color={color} />
    <span>{value}</span>
  </li>
);

export default ChartLegendLabel;
