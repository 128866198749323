import React from "react";
import spinner from "./spinner.gif";
import "./spinner.css";

export default function Spinner() {
  return (
    <div className="spinner-wrapper">
      <img src={spinner} alt="Please, wait" className="spinner" />
    </div>
  )
}