import React from 'react';
import ChartLegendLabel from './label';

const Legend = ({
  labels,
  onClick,
  filterEmotions
}) => (
  <ul className='legend'>
    {labels.map((label) => (
      <ChartLegendLabel
        key={label.value}
        onClick={() => onClick(label)}
        {...label}
        selected={filterEmotions.indexOf(label.value) >= 0}
      />
    ))}
  </ul>
);

export default Legend;
