import React from 'react';

let Preloader = ({size = ""}) => {
  return (
    <div className={"preloader " + size}>
      <i className="material-icons">donut_large</i>
    </div>
  )
}

export default Preloader;
