import React from 'react';
import PanelMain from './main';
import PanelAside from './aside';
import Toolbar from '../toolbar';
import Videos from '../videos/Videos.jsx';
import Chart from '../chart';
import Editor from '../editor';
import Timeline from '../timeline/Timeline';

const Panel = (props) => {
  return (
    <div className="panel">
      <PanelMain>
        <Videos
          respondentPath={props.videopath}
          stimulusPath={props.stimulusPath}
          fps={props.fps}
          totalFrames={props.totalFrames}
          currentFrame={props.currentFrame}
          updateCurrentFrame={props.updateCurrentFrame}
          originalLength={props.videoLength}
          onTimeUpdate={props.onTimeUpdate}
          onPause={props.onPause}
          onPlay={props.onPlay}
          openFrameData={props.onSelect}
          data={props.chartData}
          playbackRateAll={props.playbackRateAll}
          setPlaybackRateAll={props.setPlaybackRateAll}
          isScreenCapture={props.isScreenCapture}
          jumpToTime={props.jumpToTime}
        />
        {
          props.isScreenCapture ?
            <Timeline data={props.timelines} saveTimeline={props.saveTimeline} /> :
            < Chart
              data={props.chartData}
              currentFrame={props.currentFrame}
              updateCurrentFrame={props.updateCurrentFrame}
              onSelect={props.onSelect}
              onSelectRange={props.onSelectRange}
              range={props.range}
              setRange={props.setRange}
              isPlaying={props.playing}
              deselectAll={props.deselectAll}
              selected={props.selected}
              fps={props.fps}
              videopath={props.videopath}
              isScreenCapture={props.isScreenCapture}
            />
        }
      </PanelMain>
      <PanelAside>
        {
          props.isScreenCapture ?
            null :
            (
              <>
                <Toolbar
                  title={props.filepath}
                  selectedDir={props.selectedDir}
                  onNormalize={props.onNormalize}
                  onSave={props.onSave}
                  onUndo={props.onUndo}
                  onClose={props.onClose}
                  touched={props.touched}
                  isReadOnly={props.isReadOnly}
                  setOneToNeutral={props.setOneToNeutral}
                />
                <Editor
                  frames={props.frames}
                  data={props.editorData}
                  onEdit={props.onEdit}
                  onCancel={props.onCancel}
                  isReadOnly={props.isReadOnly}
                  fps={props.fps}
                />
              </>
            )
        }
      </PanelAside>
    </div>
  )
};

export default Panel;
