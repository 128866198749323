import React, {Component} from 'react';
import EditorForm from '../form';
import {normalizeState, clampState} from '../utils';

const nullify = (data) => {
  return Object.keys(data).reduce((res, key) => {
    return {
      ...res,
      [key]: '',
    };
  }, {});
};

class EditorMulty extends Component {
  state = nullify(this.props.data);

  onChange = (evt, normalize=false) => {
    const {name, value} = evt.target;

    if (normalize) {
      this.setState(normalizeState(name));
      return;
    }

    this.setState(clampState(name, value));
  };

  render() {
    return (
      <EditorForm
        data={this.state}
        frames={this.props.frames}
        onChange={this.onChange}
        onEdit={() => this.props.onEdit(this.state)}
        onCancel={this.props.onCancel}
        isReadOnly={this.props.isReadOnly}
      />
    );
  }
};

export default EditorMulty;
