import React from 'react';
import classnames from 'classnames';

const Label = ({
  className,
  ...props
}) => (
  <label className={classnames('input-label', {[className]: className})}
    {...props}
  />
);

export default Label;
