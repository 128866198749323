import React, { useState, useEffect } from 'react';

export default function ContextMenu({ content, bindingEl, setData }) {
  let [isVisible, setIsVisible] = useState(false);
  let [style, setStyle] = useState({
    top: '0px',
    left: '0px'
  });

  useEffect(() => {
    document.addEventListener('contextmenu', onContextMenu);
    document.addEventListener('click', handleClose);

    return removeListeners;
  }, []);

  function onContextMenu(e) {
    if (isBindingElEvent(e)) {
      e.preventDefault();
      setIsVisible(true);
      setStyle({
        top: e.y + 'px',
        left: e.x + 'px'
      });
      setData(e.target.closest(bindingEl).dataset.contextmenudata);
      return;
    }

    setIsVisible(false);
  }

  function handleClose(e) {
    setIsVisible(false);
  }

  function isBindingElEvent(e) {
    return e.target.closest(bindingEl) !== null;
  }

  function removeListeners() {
    document.removeEventListener('contextmenu', onContextMenu);
    document.removeEventListener('click', handleClose);
  }

  return (
    <div className={'context-menu ' + (isVisible ? 'visible' : 'hidden')} style={style}>
      <div className="context-menu--content">
        {content}
      </div>
    </div>
  )
}
