import React, { Component } from 'react';
import { IconButton } from '../common';

const CHART_SHORTCUTS = [
  { key1: 'Shift', key2: 'N', description: 'neutral' },
  { key1: 'Shift', key2: 'H', description: 'happy' },
  { key1: 'Shift', key2: 'S', description: 'surprised' },
  { key1: 'Shift', key2: 'D', description: 'disgusted' },
  { key1: 'Shift', key2: 'A', description: 'afraid' },
  { key1: 'Shift', key2: 'T', description: 'sad' },
  { key1: 'Shift', key2: 'I', description: 'interested' },
  { key1: 'Shift', key2: 'C', description: 'confused' },
  { key1: 'Shift', key2: 'Mouse wheel', description: 'zoom in / zoom out' },
  { key1: 'Shift', key2: 1, description: 'drop first marker' },
  { key1: 'Shift', key2: 2, description: 'drop second marker' }
];

const VIDEO_SHORTCUTS = [
  { isKeyCombination: false, keyboard: '&rarr;', description: 'jump 1 frame forward' },
  { isKeyCombination: true, key1: 'CTRL', key2: '&rarr;', description: '"slide" forwards' },
  { isKeyCombination: false, keyboard: '&larr;', description: 'jump 1 frame backward' },
  { isKeyCombination: true, key1: 'CTRL', key2: '&larr;', description: '"slide" backwards' },
  { isKeyCombination: false, keyboard: 'Space', description: 'play / pause' },
  { isKeyCombination: false, keyboard: '&uarr;', description: 'speed up' },
  { isKeyCombination: true, key1: 'Shift', key2: '&rarr;', description: 'jump 1s forward' },
  { isKeyCombination: false, keyboard: '&darr;', description: 'slow down' },
  { isKeyCombination: true, key1: 'Shift', key2: '&larr;', description: 'jump 1s backward' },
];

function KeyCombination({ key1, key2, description }) {
  return (
    <div className="shortcut">
      <div className="key-combination">
        <kbd>{key1}</kbd>
        <span>+</span>
        <kbd dangerouslySetInnerHTML={{ __html: key2 }}></kbd>
      </div>
      <p>{description}</p>
    </div>
  );
};

function Shortcut({ keyboard, description }) {
  return (
    <div className="shortcut">
      <kbd dangerouslySetInnerHTML={{ __html: keyboard }}></kbd>
      <p>{description}</p>
    </div>
  );
};

export default class Shortcuts extends Component {

  render() {
    let modalStyle = this.props.visible ? { display: 'flex' } : { display: 'none' };

    return (
      <div id="app-modal" className="shortcuts" style={modalStyle}>
        <div className="modal-overlay" onClick={this.props.hideModal}></div>
        <div className="modal-content">
          <div className="modal-header">
            <h5>Keyboard shortcuts</h5>
            <IconButton>
              <i className="material-icons" onClick={this.props.hideModal}>close</i>
            </IconButton>
          </div>

          <div className="modal-body">
            <div className="shortcuts-block">
              <h3>Videos </h3>
              <div className="shortcut-items">
                {
                  VIDEO_SHORTCUTS.map((shortcut, i) => shortcut.isKeyCombination ?
                    <KeyCombination {...shortcut} key={i} /> :
                    <Shortcut {...shortcut} key={i} />)
                }
              </div>

            </div>

            <div className="shortcuts-block">
              <h3>Chart</h3>
              <div className="shortcut-items">
                {
                  CHART_SHORTCUTS.map((shortcut, i) => {
                    return <KeyCombination {...shortcut} key={i} />
                  })
                }
              </div>
            </div>

            <div className="shortcuts-block">
              <h3>Other</h3>
              <div className="shortcut-items">
                <KeyCombination key1="Alt" key2="N" description="All to neutral" />
                <KeyCombination key1="Alt" key2="C" description="Set a status of an active file to cleaned" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
