import React from 'react';

const ExplorerHeader = ({
  name,
  expanded,
  onClick,
}) => {
  let style = name.project ? {display: 'block'} : {display: 'none'};
  return (
    <div className='explorer_item explorer_item--folder'
      onClick={onClick}
      style={style}
    >
      <div className="explorer-title">
        <div>
          <span> {name.project} &#8811; </span>
          <span> {name.cell} &#8811; </span>
          <span> {name.task} &#8811; </span>
          <span> {name.stimulus} </span>
        </div>
      </div>
    </div>
)};

export default ExplorerHeader;
