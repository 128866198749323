import React from 'react';
import Select from './common/Select';
import Label from './common/Label';
import Button from './common/Button';

export default class UserValidationFilter extends React.Component {
  uploadFile = event => {
    var input = event.target;
    if (!input.files.length) return;

    var reader = new FileReader();
    reader.onload = () => {
      var text = reader.result;
      this.parseCSV(text);
      input.value = '';
    };
    reader.readAsText(input.files[0]);
  }

  parseCSV = csv => {
    let users = csv.replace(/"/g, "").split('\n').splice(1).filter(line => line.length && line.indexOf(',') !== 0);
    this.props.validateUsers(users,  'validated');
  }

  render() {
    return (
      <div className="user-validation-filter">
      <div className="validation-options">
        <Select defaultValue={this.props.validationGroup} onChange={this.props.setValidationGroup}>
          <option value="all">All users</option>
          <option value="validated">Only validated users</option>
        </Select>
      </div>

      {
        this.props.validationGroup === 'validated' ?
        <div className="validation-input">
          <input type="file" onChange={this.uploadFile} id="val-file"/>
          <Label htmlFor="val-file" className="btn">
            Upload
          </Label>
        </div> : null
      }
    </div>
  )
  }
}
